import constants from './constants';

const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

/**
 * Retrieves the user's id_token from the URL or from local storage.
 * If it's empty, redirects to the login URL.
 * @returns string  The id_token
 */
const getIdToken = (): string => {
  const url: string = window.location.hash.substring(1);
  let idToken: string = (new URLSearchParams(url)).get('id_token') || '';
  if (idToken) {
    setIdToken(idToken);
  } else {
    idToken = localStorage.getItem('id_token') || '';
  }
  return idToken;
};

/**
 * Saves the user's id_token to local storage.
 */
const setIdToken = (idToken: string) => {
  localStorage.setItem('id_token', idToken);
};

/**
 * Clears the user's id_token from local storage,
 * and redirects to the login URL.
 */
const logOut = () => {
  setIdToken('');
  window.location.assign(constants.adminUrl + 'log-in');
}

/**
 * Logs an error
 */
const logError = (err: any) => {
  // if (constants.env === 'production') { return; }
  console.log('Error: ', err);
}

/**
 * Converts a value into a float number within a given range
 */
const _getFloat = (val: any, min: number, max: number, def: number) => {
  if (typeof val === 'string') { val = parseFloat(val) || def; }
  else if (typeof val !== 'number') { val = def; }
  return Math.max(min, Math.min(max, val));
};

/**
 * Converts a value into a valid GPS latitude
 */
const getLatitude = (val: any) => {
  return _getFloat(val, -90, 90, 0);
};

/**
 * Converts a value into a valid GPS longitude
 */
const getLongitude = (val: any) => {
  return _getFloat(val, -179.9999999, 180, 0);
};

/**
 * Creates a Date object and prints its date part
 */
const formatDate = (val: any, defaultString: string = 'none'): string => {
  if (!val) { return defaultString; }
  const d = new Date(val);
  if (!d.getTime()) { return defaultString; }
  return days[d.getDay()] +', '+ months[d.getMonth()] +' '+ d.getDate() + ', '+ d.getFullYear();
};

const formatDateTime = (val: any): string => {
  const dateStr = formatDate(val, '');
  if (!dateStr) { return dateStr; }
  const d = new Date(val);
  let h = d.getHours();
  const amPm = (h < 12 ? 'AM' : 'PM');
  h = (h === 0 ? 12 : (h > 12 ? h -= 12 : h));
  const m = (d.getMinutes() < 10 ? '0' : '') + d.getMinutes();
  const s = (d.getSeconds() < 10 ? '0' : '') + d.getSeconds();
  return dateStr + ', ' + h + ':' + m + ':' + s + ' ' + amPm;
};

const twoDigits = (n: number) => ((n < 10 ? '0' : '') + n);

const isoToLocal = (iso: string) => {
  if (!iso) { return ''; }
  const d = new Date(iso);
  if (!d.getTime()) { return ''; }
  return d.getFullYear() +'-'+ twoDigits(d.getMonth() + 1) +'-'+ twoDigits(d.getDate()) +'T'+
    twoDigits(d.getHours()) +':'+ twoDigits(d.getMinutes());
};

const utils = {
  getIdToken,
  setIdToken,
  logOut,
  logError,
  getLatitude,
  getLongitude,
  formatDate,
  formatDateTime,
  isoToLocal,
};

export default utils;
