import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Button from '@mui/material/Button';

import constants from '../../app/constants';

const BackButton = () => {
  return (
    <Button variant="contained" color="primary"
      sx={{ minWidth: constants.buttonMinWidth }}
      startIcon={<ArrowBackIosNewIcon />}
      onClick={() => window.history.back()}
    >
      Back
    </Button>
  );

};

export default BackButton;
