import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import Loading from '../Loading/Loading';
import api from '../../app/api';
import constants from '../../app/constants';
import * as Types from '../../app/types';

const Categories = () => {

  // define state

  const [categories, setCategories] = useState<Types.Category[]>([]);
  const [status, setStatus] = useState<Types.ComponentState>('loading');
  const initDelete = { isOpen: false, categoryId: 0, categoryName: '' };
  const [deleteState, setDeleteState] = useState(initDelete);
  const theme = useTheme();
  const isScreenMd = useMediaQuery(theme.breakpoints.up('md'));
  const isScreenSm = useMediaQuery(theme.breakpoints.up('sm'));

  // load categories

  useEffect(() => {
    (async () => {
      setCategories(await api.getCategories());
      setStatus('complete');
    })();
  }, []);

  // define grid columns

  const iconsStyle = { color: constants.listIconColor, mx: (isScreenSm ? 1 : 0) };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 2,
    },
    {
      field: 'slug',
      headerName: 'Slug',
      flex: 2,
      filterable: false,
      hide: !isScreenMd,
    },
    {
      field: 'category_group',
      headerName: 'Category group',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      hide: !isScreenSm,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      disableExport: true,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <>
          <IconButton component={Link} to={'/categories/edit/' + params.row.slug} sx={iconsStyle}>
            <EditIcon />
          </IconButton>
          <IconButton sx={iconsStyle} onClick={() => {
            setDeleteState({ isOpen: true, categoryId: params.row.id, categoryName: params.row.name });
          }}>
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  // render

  const buttonsStyle = { mt: '2px', mx: (isScreenMd ? 4 : 2), mb: 2 };

  return (
    <>
      { status === 'loading' && (
        <Loading />
      )}
      { status === 'complete' && !categories.length && (
        <div style={{ textAlign: 'center' }}>No categories found</div>
      )}
      { status === 'complete' && !!categories.length && (
        <>
          <Box sx={{ textAlign: 'center' }}>
            <Button variant="contained" startIcon={<AddIcon />} component={Link} to="/categories/new" sx={buttonsStyle}>
              Add Category
            </Button>
          </Box>

          <DataGrid
            rows={categories}
            columns={columns}
            autoHeight
            rowsPerPageOptions={constants.pageSizeOptions}
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
            disableSelectionOnClick
            disableColumnFilter
            disableColumnSelector
            sx={{ boxShadow: 2, backgroundColor: 'white' }}
            initialState={{
              pagination: {
                pageSize: constants.pageSizeOptions[0],
              },
            }}
          />

          <Dialog open={deleteState.isOpen}
            onClose={() => setDeleteState(initDelete)}
            aria-describedby="delete-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="delete-dialog-description" sx={{ color: 'black' }}>
                Delete the category {deleteState.categoryName}?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setDeleteState(initDelete)} autoFocus>No</Button>
              <Button variant="contained" color="error" onClick={async () => {
                const result = await api.deleteCategory(deleteState.categoryId);
                if (result) {
                  setCategories(cs => cs.filter(c => c.id !== deleteState.categoryId));
                }
                setDeleteState(initDelete);
              }}>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );

};

export default Categories;
