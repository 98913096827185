import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';

import Content from '../Content/Content';
import Loading from '../Loading/Loading';
import Nav from '../Nav/Nav';

import constants from '../../app/constants';
import * as Types from '../../app/types';
import utils from '../../app/utils';

function AppRoot() {

  const [status, setStatus] = useState<Types.ComponentState>('loading');
  const [isLoggedIn, setIsLoggedIn] = useState<Boolean>(false);
  const [isLoginPage, setIsLoginPage] = useState<Boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const _isLoggedIn = !!utils.getIdToken();
    const _isLoginPage = location.pathname === '/log-in';
    if (!_isLoggedIn && !_isLoginPage && constants.env === 'production') {
      // only redirect in production,
      // to avoid the 'navigation not implemented' error in unit tests,
      // and to be able to see any errors in development
      navigate('/log-in');
    }
    setIsLoggedIn(_isLoggedIn);
    setIsLoginPage(_isLoginPage);
    setStatus('complete');
  }, [location, navigate]);

  return (
    <>
      <CssBaseline />

      { status === 'loading' && (
        <Loading />
      )}
      { status === 'complete' && (isLoggedIn || isLoginPage) && (
        <>
          {!isLoginPage && (<Nav />)}
          <Content />
        </>
      )}
      { status === 'complete' && !(isLoggedIn || isLoginPage) &&
      constants.env === 'development' && (
        <Box sx={{ display: 'flex', pt: 4 }} justifyContent="center">
          <Button variant="contained"
            onClick={() => { navigate('/log-in'); }}
          >
            Log in
          </Button>
        </Box>
      )}

    </>
  );

}

export default AppRoot;
