import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { debounce } from 'lodash-es';

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import BackButton from '../BackButton/BackButton';
import Loading from '../Loading/Loading';
import SaveButton from '../SaveButton/SaveButton';
import SaveSnackbar from '../SaveSnackbar/SaveSnackbar';

import api from '../../app/api';
import constants from '../../app/constants';
import * as Types from '../../app/types';
import utils from '../../app/utils';

const emptyUser: Types.UserDetail = {
  id: '', vendor_id: null, first_name: '', last_name: '', email: '', group: '',
  role: '', company_name: '', company_website: '', subscription_end: '',
  emailVerified: false, isEnabled: false, status: ''
};

const User = () => {

  // define state

  const [status, setStatus] = useState<Types.ComponentState>('loading');
  const [saveState, setSaveState] = useState<Types.SaveState>('closed');
  const [user, setUser] = useState<Types.UserDetail|null>(null);
  const [vendorState, setVendorState] = useState<Types.VendorAutocomplete>({ input: '', loading: false, options: [] });
  const urlParams = useParams();

  // initialize

  useEffect(() => {
    (async () => {
      const loadedUser = urlParams.id
        ? await api.getUser(urlParams.id)
        : emptyUser;
      if (loadedUser) {
        setUser(loadedUser);
        if (loadedUser.vendor?.id) {
          setVendorState(a => ({...a, options: [
            { ...loadedUser.vendor }
          ]}));
        }
      }
      setStatus('complete');
    })();
  }, [urlParams]);

  // define validation

  const [formErrors, setFormErrors] = useState<Types.FormErrors>({});

  const updateUser = (field: string) => (e: any) => {
    setFormErrors(e => ({ ...e, [field]: '' }));
    setUser(u => ({ ...u, [field]: e.target.value } as Types.UserDetail));
  };

  const isValid = (user: Types.UserDetail): boolean => {
    if (!user) { return false; }
    const errors: Types.FormErrors = {};
    if (!constants.emailRegEx.test(user.email.trim())) {
      errors.email = 'Please enter a valid email';
    }
    const website = user.company_website.trim();
    if (website && !constants.urlRegEx.test(website)) {
      errors.company_website = 'Please enter a valid company website';
    }
    setFormErrors(errors);
    return !Object.keys(errors).length;
  };

  // vendor autocomplete

  const getVendors = useMemo(() => {
    return debounce(
      async (
        filter: string,
        callback: (result: any) => void,
      ) => {
        const result = await api.getVendors({
          page: 0, pageSize: 10, filter, sort: 'asc', pending_review: '0'
        });
        callback(result);
      },
      500,
    );
  }, []);

  useEffect(() => {
    if (!vendorState.loading) { return; }
    const input = vendorState.input.trim();
    if (!input) {
      setVendorState(s => ({ ...s, loading: false, options: [] }));
      return;
    }
    getVendors(input, (result: any) => {
      setVendorState(s => ({ ...s, loading: false, options: result.vendors || [] }));
    });
  }, [vendorState, getVendors]);

  // render

  return (
    <>
      { status === 'loading' && (
        <Loading />
      )}
      { status === 'complete' && (
        <>

          { !!user && (
            <>

              <Stack direction="row" sx={{ pb: 2 }} spacing={4} justifyContent="center">
                <Box>
                  <BackButton />
                </Box>
                <Box>
                  <SaveButton onClick={async () => {
                    if (saveState === 'info' || !isValid(user)) { return; }
                    setSaveState('info');
                    const result = await (user?.id
                      ? api.updateUser(user)
                      : api.createUser(user)
                    );
                    if (result && !result.error) {
                      if (!user?.id) { setUser(emptyUser); }
                      setSaveState('success');
                    } else {
                      if (result?.error?.field && result.error.message) {
                        setFormErrors(e => ({ ...e, [result.error.field]: result.error.message }));
                      }
                      setSaveState('error');
                    }
                  }} />
                </Box>
              </Stack>

              <Grid container spacing={4} justifyContent="center">

                <Grid item xs={12} md={6}>
                  <Paper elevation={3} sx={{ p: 2 }}>
                    <Stack spacing={2}>
                      <Typography variant="button">User Details</Typography>

                      <TextField label="First Name" value={user.first_name}
                        error={!!formErrors['first_name']} helperText={formErrors['first_name']}
                        onChange={updateUser('first_name')} fullWidth />

                      <TextField label="Last Name" value={user.last_name}
                        error={!!formErrors['last_name']} helperText={formErrors['last_name']}
                        onChange={updateUser('last_name')} fullWidth />

                      <TextField label="Email" value={user.email} required
                        error={!!formErrors['email']} helperText={formErrors['email']}
                        onChange={updateUser('email')} fullWidth />

                      <Autocomplete id="user-group" fullWidth options={constants.userGroups}
                        value={user.group || 'User'}
                        onChange={(e: any, newGroup: string|null) => {
                          setFormErrors(e => ({ ...e, group: '' }));
                          setUser(u => {
                            return { ...u, group: (newGroup || '') } as Types.UserDetail;
                          });
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label="Group" required
                            error={!!formErrors['group']} helperText={formErrors['group']}
                            inputProps={{ ...params.inputProps, autoComplete: 'new-password', }}
                          />
                        )}
                      />

                      <Autocomplete id="user-vendor" fullWidth options={vendorState.options}
                        getOptionLabel={o => o.name}
                        value={vendorState.options.find(v => v.id === user.vendor_id) || null}
                        noOptionsText={!vendorState.input ? 'Type a vendor name' : 'No options'}
                        loading={vendorState.loading}
                        filterOptions={o => o}
                        onInputChange={(e: any, input, reason) => {
                          if (reason !== 'input') { return; }
                          setVendorState(s => ({ ...s, input, loading: true }));
                        }}
                        onChange={(e: any, newVendor: Types.VendorListing|null) => {
                          setUser(u => ({...u, vendor_id: newVendor?.id || null} as Types.UserDetail));
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label="Vendor"
                            inputProps={{ ...params.inputProps, autoComplete: 'new-password', }}
                          />
                        )}
                      />

                      <TextField label="Role" value={user.role}
                        error={!!formErrors['role']} helperText={formErrors['role']}
                        onChange={updateUser('role')} fullWidth />

                      <TextField label="Company Name" value={user.company_name}
                        error={!!formErrors['company_name']} helperText={formErrors['company_name']}
                        onChange={updateUser('company_name')} fullWidth />

                      <TextField label="Company Website" value={user.company_website}
                        error={!!formErrors['company_website']} helperText={formErrors['company_website']}
                        onChange={updateUser('company_website')} fullWidth />

                      {!!user.id && (
                        <>
                          <Typography>User ID: {user.id}</Typography>
                          <Typography>Email Verified: {user.emailVerified ? 'yes' : 'no'}</Typography>
                          <Typography>Enabled: {user.isEnabled ? 'yes' : 'no'}</Typography>
                          <Typography>Status: {(user.status || '').toLowerCase().replace(/_/g, ' ')}</Typography>
                          <Typography>
                            Subscription End: {user.subscription_end ? utils.formatDate(user.subscription_end) : 'none'}
                          </Typography>
                        </>
                      )}

                    </Stack>
                  </Paper>
                </Grid>
              </Grid>

              <SaveSnackbar state={saveState} setState={setSaveState} />
            </>
          )}

          { !user && (
            <>
              <div style={{ textAlign: 'center' }}>The user was not found</div>
              <Stack direction="row" justifyContent='center' sx={{ pt: 4 }}>
                <BackButton />
              </Stack>
            </>
          )}

        </>
      )}
    </>
  );

};

export default User;
