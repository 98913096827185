import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import BackButton from '../BackButton/BackButton';
import Loading from '../Loading/Loading';
import SaveButton from '../SaveButton/SaveButton';
import SaveSnackbar from '../SaveSnackbar/SaveSnackbar';

import api from '../../app/api';
import * as Types from '../../app/types';

const emptyCategory: Types.Category = {
  id: 0, name: '', slug: '', category_group_id: 0, category_group: ''
};

const Category = () => {

  // define state

  const [status, setStatus] = useState<Types.ComponentState>('loading');
  const [saveState, setSaveState] = useState<Types.SaveState>('closed');
  const [category, setCategory] = useState<Types.Category|null>(null);
  const urlParams = useParams();

  const [categoryGroups, setCategoryGroups] = useState<Types.CategoryGroup[]>([]);

  // initialize

  useEffect(() => {
    (async () => {
      const loadedCategory = urlParams.slug
        ? await api.getCategory(urlParams.slug)
        : emptyCategory;
      if (loadedCategory) {
        setCategory(loadedCategory);
        setCategoryGroups(await api.getCategoryGroups());
      }
      setStatus('complete');
    })();
  }, [urlParams]);

  // define validation

  const [formErrors, setFormErrors] = useState<Types.FormErrors>({});

  const updateCategory = (field: string) => (e: any) => {
    setFormErrors(e => ({ ...e, [field]: '' }));
    setCategory(c => ({ ...c, [field]: e.target.value } as Types.Category));
  };

  const isValid = (category: Types.Category): boolean => {
    if (!category) { return false; }
    const errors: Types.FormErrors = {};
    if (!category.name.trim()) {
      errors.name = 'Please enter a name';
    }
    if (!category.category_group_id) {
      errors.category_group_id = 'Please select a category group';
    }
    setFormErrors(errors);
    return !Object.keys(errors).length;
  };

  // render

  return (
    <>
      { status === 'loading' && (
        <Loading />
      )}
      { status === 'complete' && (
        <>

          { !!category && (
            <>

              <Stack direction="row" sx={{ pb: 2 }} spacing={4} justifyContent="center">
                <Box>
                  <BackButton />
                </Box>
                <Box>
                  <SaveButton onClick={async () => {
                    if (saveState === 'info' || !isValid(category)) { return; }
                    setSaveState('info');
                    const result = await (category?.id ? api.updateCategory(category) : api.createCategory(category));
                    if (result && !result.error) {
                      if (!category?.id) { setCategory(emptyCategory); }
                      setSaveState('success');
                    } else {
                      if (result?.error?.field && result.error.message) {
                        setFormErrors(e => ({ ...e, [result.error.field]: result.error.message }));
                      }
                      setSaveState('error');
                    }
                  }} />
                </Box>
              </Stack>

              <Grid container spacing={4} justifyContent="center">

                <Grid item xs={12} md={6}>
                  <Paper elevation={3} sx={{ p: 2 }}>
                    <Stack spacing={2}>

                      <Typography variant="button">Category Details</Typography>

                      <Stack spacing={2}>

                        <TextField label="Name" value={category.name} required
                          error={!!formErrors['name']} helperText={formErrors['name']}
                          onChange={updateCategory('name')} fullWidth />
                        <TextField label="Slug" value={category.slug}
                          error={!!formErrors['slug']} helperText={formErrors['slug']}
                          onChange={updateCategory('slug')} fullWidth />

                        <Autocomplete id="category-group" fullWidth options={categoryGroups}
                          getOptionLabel={(group) => group.name}
                          value={categoryGroups.find(g => g.id === category.category_group_id) || null}
                          onChange={(e: any, newGroup: Types.CategoryGroup|null) => {
                            setFormErrors(e => ({ ...e, category_group_id: '' }));
                            setCategory(c => {
                              return { ...c, category_group_id: (newGroup?.id || 0) } as Types.Category;
                            });
                          }}
                          renderInput={(params) => (
                            <TextField {...params} label="Category Group" required
                              error={!!formErrors['category_group_id']} helperText={formErrors['category_group_id']}
                              inputProps={{ ...params.inputProps, autoComplete: 'new-password', }}
                            />
                          )}
                        />

                      </Stack>

                    </Stack>
                  </Paper>
                </Grid>
              </Grid>

              <SaveSnackbar state={saveState} setState={setSaveState} />
            </>
          )}

          { !category && (
            <>
              <div style={{ textAlign: 'center' }}>The category was not found</div>
              <Stack direction="row" justifyContent='center' sx={{ pt: 4 }}>
                <BackButton />
              </Stack>
            </>
          )}

        </>
      )}
    </>
  );

};

export default Category;
