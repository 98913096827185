import { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import api from '../../app/api';
import geocoder, { Address } from '../../app/geocoder';
import * as Types from '../../app/types';

const BatchGeocoding = () => {

  const [batchType, setBatchType] = useState<string>('vendors');
  const [isGeocoding, setIsGeocoding] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [lastId, setLastId] = useState<number>(0);

  useEffect(() => {
    if (!isGeocoding) { return; }
    const batchSize = 1, sleepTime = 1000;
    window.setTimeout(async () => {
      // get batch
      const batch = await api.getItemsToGeocode(batchType, lastId, batchSize);
      if (!batch?.count) {
        setMessage('No more ' + batchType + ' to geocode');
        setIsGeocoding(false);
        return;
      }
      // process batch
      let newLastId = lastId;
      for (const i of batch.items) {
        const item = (batchType === 'projects' ? i as Types.ProjectDetail : i as Types.VendorDetail);
        newLastId = item.id;
        const gResult = await geocoder.geocode(item as Address);
        if (!gResult || gResult.error) {
          console.log(item.id + ': ' + gResult.error || '');
          continue;
        }
        item.latitude = gResult.location.latitude;
        item.longitude = gResult.location.longitude;
        await api.updateItemLocation(batchType, item);
      }
      // rerender and repeat
      setLastId(newLastId);
      setMessage((batch.count - batchSize) + ' remaining ' + batchType);
    }, sleepTime + Math.round(sleepTime * Math.random()));
  }, [batchType, isGeocoding, message, lastId]);

  return (
    <Grid container spacing={4} sx={{ justifyContent: 'center' }}>

      <Grid item xs={12} md={6}>
        <Paper elevation={3} sx={{ p: 2 }}>
          <Stack spacing={2} alignItems="center">

            <FormControl fullWidth>
              <InputLabel id="batch-select-label">Batch type</InputLabel>
              <Select
                labelId="batch-select-label"
                id="batch-select"
                value={batchType}
                label="Batch type"
                onChange={e => {
                  setBatchType(e.target.value);
                  setMessage('');
                  setLastId(0);
                }}
                disabled={isGeocoding}
              >
                <MenuItem value="projects">Projects</MenuItem>
                <MenuItem value="vendors">Vendors</MenuItem>
              </Select>
            </FormControl>

            <Button variant="contained" color={!isGeocoding ? 'primary' : 'error'}
              onClick={() => setIsGeocoding(v => !v)}
            >
              {!isGeocoding ? 'Start' : 'Stop'}
            </Button>

            {!!message && (
              <Typography>{message}</Typography>
            )}

          </Stack>
        </Paper>
      </Grid>

    </Grid>
  );

};

export default BatchGeocoding;
