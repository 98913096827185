import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { DataGrid, GridColDef, GridSortModel, GridToolbar } from '@mui/x-data-grid';

import api from '../../app/api';
import constants from '../../app/constants';
import * as Types from '../../app/types';
import utils from '../../app/utils';

const Jobs = () => {

  // define state

  const [jobs, setJobs] = useState<Types.JobListing[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [rowCount, setRowCount] = useState<number>(0);
  const [gridState, setGridState] = useState<Types.GridState>({
    page: 0, pageSize: constants.pageSizeOptions[0], sort: '', sort_by: ''
  });
  const initDelete = { isOpen: false, jobId: 0, jobTitle: '' };
  const [deleteState, setDeleteState] = useState(initDelete);
  const theme = useTheme();
  const isScreenMd = useMediaQuery(theme.breakpoints.up('md'));

  // load jobs

  const loadJobs = async (gridState: Types.GridState) => {
    setIsLoading(true);
    const result = await api.getJobs(gridState);
    setJobs(result.jobs || []);
    setRowCount(result.count || 0);
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => await loadJobs(gridState))();
  }, [gridState]);

  // define grid columns

  const iconsStyle = { color: constants.listIconColor };

  const columns: GridColDef[] = [
    {
      field: 'title',
      headerName: 'Title',
      flex: isScreenMd ? 2 : 1,
      filterable: false,
    },
    {
      field: 'updated_at',
      headerName: 'Updated at',
      flex: 2,
      filterable: false,
      hide: !isScreenMd,
      renderCell: (params) => utils.formatDateTime(params.row.updated_at),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      disableExport: true,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <>
          <IconButton component={Link} to={'/jobs/edit/' + params.row.id} sx={iconsStyle}>
            <EditIcon />
          </IconButton>
          <IconButton sx={iconsStyle} onClick={() => {
            setDeleteState({ isOpen: true, jobId: params.row.id, jobTitle: params.row.title });
          }}>
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  // render

  const buttonsStyle = { mt: '2px', mx: (isScreenMd ? 4 : 2), mb: 2 };

  return (
    <>

      <Box sx={{ textAlign: 'center' }}>
        <Button variant="contained" startIcon={<AddIcon />} component={Link} to="/jobs/new" sx={buttonsStyle}>
          Add Job
        </Button>
      </Box>

      <DataGrid
        rows={jobs}
        columns={columns}
        autoHeight
        rowsPerPageOptions={constants.pageSizeOptions}
        components={{ Toolbar: GridToolbar }}
        disableSelectionOnClick
        disableColumnFilter
        disableColumnSelector
        sx={{ boxShadow: 2, backgroundColor: 'white' }}
        paginationMode="server"
        rowCount={rowCount}
        loading={isLoading}
        page={gridState.page}
        onPageChange={page => setGridState(s => ({ ...s, page }))}
        pageSize={gridState.pageSize}
        onPageSizeChange={pageSize => setGridState(s => ({ ...s, pageSize }))}
        sortingMode="server"
        onSortModelChange={(sortModel: GridSortModel) => setGridState(s => ({
          ...s, sort: (sortModel?.length ? sortModel[0].sort : '') + '',
          sort_by: (sortModel?.length ? sortModel[0].field : '') + ''
        }))}
      />

      <Dialog open={deleteState.isOpen}
        onClose={() => setDeleteState(initDelete)}
        aria-describedby="delete-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="delete-dialog-description" sx={{ color: 'black' }}>
            Delete the job {deleteState.jobTitle} and all its applications?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteState(initDelete)} autoFocus>No</Button>
          <Button variant="contained" color="error" onClick={async () => {
            const jobId = deleteState.jobId;
            setDeleteState(initDelete);
            setIsLoading(true);
            let result = await api.deleteJob(jobId);
            if (result) { result = await api.deleteJobFiles(jobId); }
            if (result) { await loadJobs(gridState); }
            else { setIsLoading(false); }
          }}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );

};

export default Jobs;
