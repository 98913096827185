import { useCallback, useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import SaveButton from '../SaveButton/SaveButton';
import SaveSnackbar from '../SaveSnackbar/SaveSnackbar';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import Loading from '../Loading/Loading';

import api from '../../app/api';
import * as Types from '../../app/types';
import utils from '../../app/utils';

interface ProjectUpdatesParams {
  projectId: number,
  projectName: string,
  visible: boolean,
  onClose: () => void,
}

const ProjectUpdates = (params: ProjectUpdatesParams) => {

  // initialize

  const [status, setStatus] = useState<Types.ComponentState>('loading');
  const [saveState, setSaveState] = useState<Types.SaveState>('closed');
  const [updates, setUpdates] = useState<Types.ProjectUpdate[]>([]);
  const theme = useTheme();
  const isScreenSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isScreenMd = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    (async () => {
      setStatus('loading');
      setUpdates([]);
      if (!params.visible) { return; }
      if (params.projectId) {
        setUpdates((await api.getProjectUpdates(params.projectId)) || []);
      }
      setStatus('complete');
    })();
  }, [params.projectId, params.visible]);

  // event handlers

  const onSave = useCallback(async () => {
    if (saveState === 'info') { return; }
    setSaveState('info');
    const result = await api.updateProjectUpdates(params.projectId, updates);
    setSaveState(result && !result.error ? 'success' : 'error');
  }, [saveState, params.projectId, updates]);

  const addUpdate = () => {
    const newUpdate = { description: '', created_at: (new Date()).toISOString() };
    setUpdates(u => [ newUpdate, ...u ]);
  };

  // render

  return (
    <>
      <Dialog open={params.visible} onClose={params.onClose} scroll="paper" fullWidth maxWidth="md">

        <DialogTitle>
          <Stack direction="row" alignItems="center" spacing={isScreenMd ? 4 : 1}>
            <Box sx={{ flexGrow: 1 }}>
              {status === 'complete' && !!params.projectId && (
                <>
                  <Typography sx={{ fontWeight: '700' }} variant={isScreenMd ? 'body1' : 'body2'}>
                    {params.projectName}
                  </Typography>
                </>
              )}
            </Box>
            {status === 'complete' && !!params.projectId && isScreenSm && (
              <>
                <Box sx={{ flexShrink: 0 }}>
                  <Button variant="contained" startIcon={<AddIcon />} onClick={addUpdate}>
                    Add Update
                  </Button>
                </Box>
                <Box sx={{ flexShrink: 0 }}>
                  <SaveButton label="Save" onClick={onSave} />
                </Box>
              </>
            )}
            <Box sx={{ flexShrink: 0 }}>
              <IconButton sx={{ color: 'black' }} onClick={params.onClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Stack>
        </DialogTitle>

        <DialogContent dividers sx={{ py: 4 }}>
          <>
            {status === 'loading' && (
              <Loading />
            )}
            {status === 'complete' && (
              <Stack direction="column" alignItems="center" justifyContent="center" spacing={3}>
                {!!params.projectId && !isScreenSm && (
                  <>
                    <SaveButton label="Save" onClick={onSave} />
                    <Button variant="contained" startIcon={<AddIcon />} onClick={addUpdate}>
                      Add Update
                    </Button>
                  </>
                )}
                {!!params.projectId && !!updates.length && updates.map((u, index) => (
                  <Stack key={index} direction={{ xs: 'column', sm: 'row' }}
                    alignItems="center" justifyContent="center" spacing={2}
                    sx={{ width: '100%', borderBottom: '1px solid rgba(0, 0, 0, 0.12)', paddingBottom: '16px' }}
                  >
                    <Stack spacing={2} sx={{ flexGrow: 1, width: (isScreenSm ? 'initial' : '100%') }} alignItems="center">
                      <TextField label="Description" value={u.description} fullWidth multiline
                        onChange={e => setUpdates(us => {
                          const newUpdates = [ ...us ];
                          newUpdates[index].description = e.target.value;
                          return newUpdates;
                        })} />
                      <TextField type="datetime-local" label="Created at" sx={{ maxWidth: '100%' }}
                        InputLabelProps={{ shrink: true }}
                        value={utils.isoToLocal(u.created_at)}
                        onChange={e => setUpdates(us => {
                          const newUpdates = [ ...us ];
                          newUpdates[index].created_at = e.target.value;
                          return newUpdates;
                        })}
                        onBlur={e => setUpdates(us => {
                          const newUpdates = [ ...us ];
                          const d = new Date(e.target.value);
                          newUpdates[index].created_at = (d.getTime() ? d.toISOString() : '');
                          return newUpdates;
                        })} />
                    </Stack>
                    <IconButton sx={{ color: 'black' }}
                      onClick={() => setUpdates(us => {
                        const newUpdates = [ ...us ];
                        newUpdates.splice(index, 1);
                        return newUpdates;
                      })}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Stack>
                ))}
                {!updates.length && (
                  <DialogContentText>
                    {params.projectId ? 'No project updates' : 'Please save the project before adding updates'}
                  </DialogContentText>
                )}
              </Stack>
            )}
          </>
        </DialogContent>

      </Dialog>

      <SaveSnackbar state={saveState} setState={setSaveState} />
    </>
  )
};

export default ProjectUpdates;
