import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import UpdateIcon from '@mui/icons-material/Update';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { DataGrid, GridColDef, GridFilterModel, GridSortModel, GridToolbar } from '@mui/x-data-grid';

import ProjectImages from './ProjectImages';
import ProjectUpdates from './ProjectUpdates';
import ReloadButton from '../ReloadButton/ReloadButton';

import api from '../../app/api';
import constants from '../../app/constants';
import * as Types from '../../app/types';

const Projects = () => {

  // define state

  const [projects, setProjects] = useState<Types.ProjectListing[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [rowCount, setRowCount] = useState<number>(0);
  const [gridState, setGridState] = useState<Types.GridState>({
    page: 0, pageSize: constants.pageSizeOptions[0],
    filter: '', sort: '', pending_review: ''
  });
  const initPopupState = { isOpen: false, projectId: 0, projectName: '' };
  const [deleteState, setDeleteState] = useState(initPopupState);
  const [imagesState, setImagesState] = useState(initPopupState);
  const [updatesState, setUpdatesState] = useState(initPopupState);
  const theme = useTheme();
  const isScreenMd = useMediaQuery(theme.breakpoints.up('md'));
  const isScreenSm = useMediaQuery(theme.breakpoints.up('sm'));

  // load projects

  const loadProjects = async (gridState: Types.GridState) => {
    setIsLoading(true);
    const result = await api.getProjects(gridState);
    setProjects(result.projects || []);
    setRowCount(result.count || 0);
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => await loadProjects(gridState))();
  }, [gridState]);

  // define grid columns

  const iconsStyle = { color: constants.listIconColor };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: isScreenMd ? 2 : 1,
    },
    {
      field: 'slug',
      headerName: 'Slug',
      flex: 2,
      filterable: false,
      sortable: false,
      hide: !isScreenMd,
    },
    {
      field: 'pending_review',
      headerName: 'Review status',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      sortable: false,
      hide: !isScreenSm,
      renderCell: (params) => {
        if (!params.row.pending_review) { return 'Approved'; }
        const label = 'Pending | ' + (params.row.edited_project_id ? 'Edited' : 'New');
        const color = (params.row.edited_project_id ? 'info' : 'success');
        return (
          <Chip label={label} color={color} variant="outlined" />
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      disableExport: true,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <>
          <IconButton component={Link} to={'/projects/edit/' + params.row.id} sx={iconsStyle}>
            <EditIcon />
          </IconButton>
          {!params.row.pending_review && (
            <>
              <IconButton sx={iconsStyle} onClick={() => {
                setImagesState({ isOpen: true, projectId: params.row.id, projectName: params.row.name });
              }}>
                <PhotoLibraryIcon />
              </IconButton>
              <IconButton sx={iconsStyle} onClick={() => {
                setUpdatesState({ isOpen: true, projectId: params.row.id, projectName: params.row.name });
              }}>
                <UpdateIcon />
              </IconButton>
            </>
          )}
          <IconButton sx={iconsStyle} onClick={() => {
            setDeleteState({ isOpen: true, projectId: params.row.id, projectName: params.row.name });
          }}>
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  // render

  const buttonsStyle = { mt: '2px', mx: (isScreenMd ? 4 : 2), mb: 2 };

  return (
    <>

      <Box sx={{ textAlign: 'center' }}>
        <Button variant="contained" startIcon={<AddIcon />} component={Link} to="/projects/new" sx={buttonsStyle}>
          Add Project
        </Button>

        {isScreenSm && (
          <ReloadButton onClick={async () => await loadProjects(gridState)} sx={buttonsStyle} />
        )}

        <FormControl size="small" sx={{ ...buttonsStyle, width: 150, mt: 0 }}>
          <InputLabel id="review-status-label">Review status</InputLabel>
          <Select
            labelId="review-status-label"
            id="review-status-select"
            value={gridState.pending_review}
            label="Review status"
            onChange={(e) => {
              setGridState(s => ({ ...s, pending_review: e.target.value }));
            }}
            sx={{ backgroundColor: 'white' }}
          >
            <MenuItem value=''>All</MenuItem>
            <MenuItem value='0'>Approved</MenuItem>
            <MenuItem value='1'>Pending</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <DataGrid
        rows={projects}
        columns={columns}
        autoHeight
        rowsPerPageOptions={constants.pageSizeOptions}
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        disableSelectionOnClick
        disableColumnFilter
        disableColumnSelector
        sx={{ boxShadow: 2, backgroundColor: 'white' }}
        paginationMode="server"
        rowCount={rowCount}
        loading={isLoading}
        page={gridState.page}
        onPageChange={page => setGridState(s => ({ ...s, page }))}
        pageSize={gridState.pageSize}
        onPageSizeChange={pageSize => setGridState(s => ({ ...s, pageSize }))}
        filterMode="server"
        onFilterModelChange={(filterModel: GridFilterModel) => setGridState(s => ({
          ...s, filter: (filterModel?.quickFilterValues?.length ? filterModel.quickFilterValues.join(' ') : '')
        }))}
        sortingMode="server"
        onSortModelChange={(sortModel: GridSortModel) => setGridState(s => ({
          ...s, sort: (sortModel?.length ? sortModel[0].sort : '') + ''
        }))}
      />

      <Dialog open={deleteState.isOpen}
        onClose={() => setDeleteState(initPopupState)}
        aria-describedby="delete-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="delete-dialog-description" sx={{ color: 'black' }}>
            Delete the project {deleteState.projectName}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteState(initPopupState)} autoFocus>No</Button>
          <Button variant="contained" color="error" onClick={async () => {
            const projectId = deleteState.projectId;
            setDeleteState(initPopupState);
            setIsLoading(true);
            let result = await api.deleteAllProjectImages(projectId);
            if (result) { result = await api.deleteProject(projectId); }
            if (result) { await loadProjects(gridState); }
            else { setIsLoading(false); }
          }}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <ProjectImages projectId={imagesState.projectId} projectName={imagesState.projectName}
        visible={imagesState.isOpen} onClose={() => setImagesState(initPopupState)} />
      <ProjectUpdates projectId={updatesState.projectId} projectName={updatesState.projectName}
        visible={updatesState.isOpen} onClose={() => setUpdatesState(initPopupState)} />
    </>
  );

};

export default Projects;
