import { useEffect, useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';

import api from '../../app/api';
import constants from '../../app/constants';
import * as Types from '../../app/types';

const JobApps = () => {

  // define state

  const [jobApps, setJobApps] = useState<Types.JobApplication[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [rowCount, setRowCount] = useState<number>(0);
  const [gridState, setGridState] = useState<Types.GridState>({
    page: 0, pageSize: constants.pageSizeOptions[0]
  });
  const initDelete = { isOpen: false, jobId: 0, jobTitle: '', userId: '', userEmail: '' };
  const [deleteState, setDeleteState] = useState(initDelete);
  const theme = useTheme();
  const isScreenMd = useMediaQuery(theme.breakpoints.up('md'));

  // load jobs

  const loadJobApps = async (gridState: Types.GridState) => {
    setIsLoading(true);
    const result = await api.getJobApps(gridState);
    setJobApps(result.job_apps || []);
    setRowCount(result.count || 0);
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => await loadJobApps(gridState))();
  }, [gridState]);

  // define grid columns

  const iconsStyle = { color: constants.listIconColor };

  const columns: GridColDef[] = [
    {
      field: 'job_title',
      headerName: 'Job Title',
      flex: isScreenMd ? 2 : 1,
      filterable: false,
      sortable: false,
    },
    {
      field: 'user_email',
      headerName: 'User',
      flex: 2,
      filterable: false,
      sortable: false,
      hide: !isScreenMd,
    },
    {
      field: 'vendor',
      headerName: 'Vendor',
      flex: 2,
      filterable: false,
      sortable: false,
      hide: !isScreenMd,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      disableExport: true,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <>
          <IconButton sx={iconsStyle} onClick={() => {
            setDeleteState({
              isOpen: true, jobId: params.row.job_id, jobTitle: params.row.job_title,
              userId: params.row.user_id, userEmail: params.row.user_email
            });
          }}>
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  // render

  return (
    <>
      <DataGrid
        rows={jobApps}
        getRowId={row => row.job_id + '/' + row.user_id}
        columns={columns}
        autoHeight
        rowsPerPageOptions={constants.pageSizeOptions}
        components={{ Toolbar: GridToolbar }}
        disableSelectionOnClick
        disableColumnFilter
        disableColumnSelector
        sx={{ boxShadow: 2, backgroundColor: 'white' }}
        paginationMode="server"
        rowCount={rowCount}
        loading={isLoading}
        page={gridState.page}
        onPageChange={page => setGridState(s => ({ ...s, page }))}
        pageSize={gridState.pageSize}
        onPageSizeChange={pageSize => setGridState(s => ({ ...s, pageSize }))}
      />

      <Dialog open={deleteState.isOpen}
        onClose={() => setDeleteState(initDelete)}
        aria-describedby="delete-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="delete-dialog-description" sx={{ color: 'black' }}>
            Delete the job application of {deleteState.userEmail} for job {deleteState.jobTitle}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteState(initDelete)} autoFocus>No</Button>
          <Button variant="contained" color="error" onClick={async () => {
            const jobId = deleteState.jobId, userId = deleteState.userId;
            setDeleteState(initDelete);
            setIsLoading(true);
            let result = await api.deleteJobApp(jobId, userId);
            if (result) { result = await api.deleteJobAppFiles(jobId, userId); }
            if (result) { await loadJobApps(gridState); }
            else { setIsLoading(false); }
          }}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );

};

export default JobApps;
