import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import constants from '../../app/constants'

const Home = () => {

  const navigate = useNavigate();

  useEffect(() => {
    navigate(constants.routes[0].path);
  }, [navigate]);

  return (
    <></>
  );

};

export default Home;
