const aws = {
  Auth: {
    region: 'ca-central-1',
    userPoolId: 'ca-central-1_0h15pEgei',
    userPoolWebClientId: '3mega7vdojp72p3tf9lojhea9u',
    signUpVerificationMethod: 'link',
  }
};

export default aws;
