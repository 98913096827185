import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import BackButton from '../BackButton/BackButton';
import DeleteButton from '../DeleteButton/DeleteButton';
import Loading from '../Loading/Loading';
import SaveButton from '../SaveButton/SaveButton';
import SaveSnackbar from '../SaveSnackbar/SaveSnackbar';
import SubText from '../SubText/SubText';

import api from '../../app/api';
import constants from '../../app/constants';
import geocoder from '../../app/geocoder';
import * as Types from '../../app/types';
import utils from '../../app/utils';

const emptyVendor: Types.VendorDetail = {
  id: 0, name: '', slug: '', description: '', website: '',
  street_number: '', street_name: '', suite_number: '', city: '',
  state_id: 0, state: '', zip: '', country_id: constants.defaultCountryId, country: '',
  phone: '', email: '', latitude: 0, longitude: 0, info_visible: 0, categories: [],
  pending_review: 0, edited_vendor_id: null,
};

const Vendor = () => {

  // define state

  const [status, setStatus] = useState<Types.ComponentState>('loading');
  const [saveState, setSaveState] = useState<Types.SaveState>('closed');
  const [vendor, setVendor] = useState<Types.VendorDetail|null>(null);
  const [editedVendor, setEditedVendor] = useState<Types.VendorDetail|null>(null);
  const [reviewDeleted, setReviewDeleted] = useState<boolean>(false);
  const [geocodingMsg, setGeocodingMsg] = useState<string>('');
  const [initialAddress, setInitialAddress] = useState<string>('');
  const urlParams = useParams();

  const [countries, setCountries] = useState<Types.Country[]>([]);
  const [states, setStates] = useState<Types.State[]>([]);
  const [categories, setCategories] = useState<Types.Category[]>([]);

  // initialize

  useEffect(() => {
    (async () => {
      const loadedVendor = urlParams.id
        ? await api.getVendor(urlParams.id)
        : emptyVendor;
      if (loadedVendor) {
        setVendor(loadedVendor);
        setCountries(await api.getCountries());
        setStates(await api.getStates(loadedVendor.country_id));
        setCategories(await api.getCategories());
        if (loadedVendor.edited_vendor_id) {
          setEditedVendor(await api.getVendor(loadedVendor.edited_vendor_id));
        }
      }
      await geocoder.load();
      setInitialAddress(geocoder.addressToString(loadedVendor));
      setStatus('complete');
    })();
  }, [urlParams]);

  // define validation

  const [formErrors, setFormErrors] = useState<Types.FormErrors>({});

  const updateVendor = (field: string) => (e: any) => {
    setFormErrors(e => ({ ...e, [field]: '' }));
    setVendor(v => ({ ...v, [field]: e.target.value } as Types.VendorDetail));
  };

  const setLatitude = (e: any) => {
    const value = utils.getLatitude(e.target.value);
    setVendor(v => ({ ...v, 'latitude': value } as Types.VendorDetail));
  };

  const setLongitude = (e: any) => {
    const value = utils.getLongitude(e.target.value);
    setVendor(v => ({ ...v, 'longitude': value } as Types.VendorDetail));
  };

  const isValid = (vendor: Types.VendorDetail): boolean => {
    if (!vendor) { return false; }
    const errors: Types.FormErrors = {};
    if (!vendor.name.trim()) {
      errors.name = 'Please enter a name';
    }
    if (!vendor.state_id) {
      errors.state_id = 'Please select a state or province';
    }
    if (!vendor.country_id) {
      errors.country_id = 'Please select a country';
    }
    const email = vendor.email.trim();
    if (email && !constants.emailRegEx.test(email)) {
      errors.email = 'Please enter a valid email';
    }
    const website = vendor.website.trim();
    if (website && !constants.urlRegEx.test(website)) {
      errors.website = 'Please enter a valid website';
    }
    setFormErrors(errors);
    return !Object.keys(errors).length;
  };

  // render

  return (
    <>
      { status === 'loading' && (
        <Loading />
      )}
      { status === 'complete' && (
        <>

          { !!vendor && (
            <>
              {!!vendor.pending_review && !reviewDeleted && (
                <Alert icon={false} sx={{ mb: 2 }}
                  severity={vendor.edited_vendor_id ? 'info' : 'success'}
                >
                  {'Pending review: ' + (editedVendor
                    ? 'Edits vendor "' + editedVendor.name +'"'
                    : 'New vendor'
                  )}
                  {vendor.updated_by && vendor.user_email && (
                    <span>. Submitted by &nbsp;
                      <a href={constants.adminUrl + 'users/edit/' + vendor.updated_by}
                      target="_blank" rel="noreferrer">
                        {vendor.user_email}
                      </a>
                    </span>
                  )}
                </Alert>
              )}

              <Stack direction="row" sx={{ pb: 2 }} spacing={4} justifyContent="center">
                <Box>
                  <BackButton />
                </Box>

                {(!vendor.pending_review || !reviewDeleted) && (
                  <Box>
                    <SaveButton
                      label={vendor.pending_review ? 'Approve' : 'Save'}
                      onClick={async () => {
                        // validate
                        if (saveState === 'info' || !isValid(vendor)) { return; }
                        setSaveState('info');
                        const v = { ...vendor } as Types.VendorDetail;
                        v.country = countries.find(c => c.id === v.country_id)?.name || '';
                        // geocode
                        const newAddress = geocoder.addressToString(v);
                        if (newAddress !== initialAddress || (!v.latitude && !v.longitude)) {
                          setGeocodingMsg('Geocoding...');
                          const gResult = await geocoder.geocode(v);
                          setGeocodingMsg(gResult.error);
                          if (gResult && !gResult.error) {
                            v.latitude = gResult.location.latitude;
                            v.longitude = gResult.location.longitude;
                            setInitialAddress(newAddress);
                          }
                        }
                        // save vendor
                        let result: any;
                        if (v.pending_review) {
                          v.pending_review = 0;
                          if (v.edited_vendor_id) {
                            v.id = v.edited_vendor_id;
                            v.edited_vendor_id = null;
                          }
                        }
                        if (v.id) {
                          result = await api.updateVendor(v);
                        } else {
                          result = await api.createVendor(v);
                        }
                        // process result
                        if (result && !result.error && result.id) {
                          if (vendor.pending_review && vendor.edited_vendor_id) {
                            await api.deleteVendor(vendor.id);  // the edit was applied to edited_vendor_id
                          }
                          v.id = result.id;  // no change unless the vendor is new
                          setVendor(v);
                          setSaveState('success');
                        } else {
                          if (result?.error?.field && result.error.message) {
                            setFormErrors(e => ({ ...e, [result.error.field]: result.error.message }));
                          }
                          setSaveState('error');
                        }
                      }}
                    />
                  </Box>
                )}

                {!!vendor.pending_review && !reviewDeleted && (
                  <Box>
                    <DeleteButton onClick={async () => {
                      setSaveState('info');
                      await api.deleteVendor(vendor.id);
                      setSaveState('success');
                      setReviewDeleted(true);
                    }} />
                  </Box>
                )}
              </Stack>

              <Grid container spacing={4}>

                <Grid item xs={12} md={6}>
                  <Paper elevation={3} sx={{ p: 2 }}>
                    <Stack spacing={2}>

                      <Typography variant="button">Vendor Details</Typography>

                      <Stack>
                        <TextField label="Name" value={vendor.name} required
                          error={!!formErrors['name']} helperText={formErrors['name']}
                          onChange={updateVendor('name')} fullWidth />
                        {!!editedVendor?.name && (
                          <SubText text={vendor.name} subText={editedVendor.name} />
                        )}
                      </Stack>

                      <Stack>
                        <TextField label="Slug" value={vendor.slug}
                          error={!!formErrors['slug']} helperText={formErrors['slug']}
                          onChange={updateVendor('slug')} fullWidth />
                        {!!editedVendor?.slug && (
                          <SubText text={vendor.slug} subText={editedVendor.slug} />
                        )}
                      </Stack>

                      <Stack sx={{ width: '100%' }}>
                        <TextField label="Description" value={vendor.description}
                          onChange={updateVendor('description')} fullWidth multiline />
                        {!!editedVendor?.description && (
                          <SubText text={vendor.description} subText={editedVendor.description} />
                        )}
                      </Stack>

                      <Stack spacing={2} direction="row">
                        <Stack sx={{ width: '100%' }}>
                          <TextField label="Website" value={vendor.website}
                            error={!!formErrors['website']} helperText={formErrors['website']}
                            onChange={updateVendor('website')} fullWidth />
                          {!!editedVendor && (
                            <SubText text={vendor.website} subText={editedVendor.website} />
                          )}
                        </Stack>

                        <Stack sx={{ width: '100%' }}>
                          <TextField label="Email" value={vendor.email}
                            error={!!formErrors['email']} helperText={formErrors['email']}
                            onChange={updateVendor('email')} fullWidth />
                          {!!editedVendor && (
                            <SubText text={vendor.email} subText={editedVendor.email} />
                          )}
                        </Stack>
                      </Stack>

                      <Stack spacing={2} direction="row">
                        <Stack sx={{ width: '100%' }}>
                          <TextField label="Phone" value={vendor.phone}
                            onChange={updateVendor('phone')} sx={{ flex: 1 }} />
                          {!!editedVendor && (
                            <SubText text={vendor.phone} subText={editedVendor.phone} />
                          )}
                        </Stack>

                        <Stack sx={{ width: '100%' }}>
                          <FormControlLabel label="Info Visible" sx={{ flex: 1 }}
                            control={<Switch
                              checked={!!vendor.info_visible}
                              onChange={(e: any) => setVendor(v => {
                                return { ...v, info_visible: e.target.checked ? 1 : 0 } as Types.VendorDetail;
                              })}
                            />}
                          />
                          {!!editedVendor && (
                            <SubText text={vendor.info_visible ? 'Yes' : 'No'} subText={editedVendor.info_visible ? 'Yes' : 'No'} />
                          )}
                        </Stack>
                      </Stack>

                      <Stack spacing={2} direction="row">
                        <Stack sx={{ width: '100%' }}>
                          <TextField label="Street Number" value={vendor.street_number}
                            onChange={updateVendor('street_number')} fullWidth />
                          {!!editedVendor && (
                            <SubText text={vendor.street_number} subText={editedVendor.street_number} />
                          )}
                        </Stack>

                        <Stack sx={{ width: '100%' }}>
                          <TextField label="Street Name" value={vendor.street_name}
                            onChange={updateVendor('street_name')} fullWidth />
                          {!!editedVendor && (
                            <SubText text={vendor.street_name} subText={editedVendor.street_name} />
                          )}
                        </Stack>

                        <Stack sx={{ width: '100%' }}>
                          <TextField label="Suite Number" value={vendor.suite_number}
                            onChange={updateVendor('suite_number')} fullWidth />
                          {!!editedVendor && (
                            <SubText text={vendor.suite_number} subText={editedVendor.suite_number} />
                          )}
                        </Stack>
                      </Stack>

                      <Stack spacing={2} direction="row">
                        <Stack sx={{ width: '100%' }}>
                          <TextField label="City" value={vendor.city}
                            onChange={updateVendor('city')} fullWidth />
                          {!!editedVendor && (
                            <SubText text={vendor.city} subText={editedVendor.city} />
                          )}
                        </Stack>

                        <Stack sx={{ width: '100%' }}>
                          <TextField label="Postal Code / Zip" value={vendor.zip}
                            onChange={updateVendor('zip')} fullWidth />
                          {!!editedVendor && (
                            <SubText text={vendor.zip} subText={editedVendor.zip} />
                          )}
                        </Stack>
                      </Stack>

                      <Stack spacing={2} direction="row">

                        <Stack sx={{ width: '100%' }}>
                          <Autocomplete id="state" fullWidth options={states}
                            getOptionLabel={(state) => state.name}
                            value={states.find(s => s.id === vendor.state_id) || null}
                            onChange={(e: any, newState: Types.State|null) => {
                              setFormErrors(e => ({ ...e, state_id: '' }));
                              setVendor(v => {
                                return {
                                  ...v, state_id: (newState?.id || 0),
                                  state: states.find(s => s.id === newState?.id)?.name || ''
                                } as Types.VendorDetail;
                              });
                            }}
                            renderInput={(params) => (
                              <TextField {...params} label="State / Province" required
                                error={!!formErrors['state_id']} helperText={formErrors['state_id']}
                                inputProps={{ ...params.inputProps, autoComplete: 'new-password', }}
                              />
                            )}
                          />
                          {!!editedVendor && (
                            <SubText text={vendor.state} subText={editedVendor.state} />
                          )}
                        </Stack>

                        <Stack sx={{ width: '100%' }}>
                          <Autocomplete id="country" fullWidth options={countries}
                            getOptionLabel={(country) => country.name}
                            value={countries.find(c => c.id === vendor.country_id) || null}
                            onChange={(e: any, newCountry: Types.Country|null) => {
                              setFormErrors(e => ({ ...e, country_id: '' }));
                              setVendor(v => {
                                return {
                                  ...v, country_id: (newCountry?.id || 0), state_id: 0, state: '',
                                  country: countries.find(c => c.id === newCountry?.id)?.name || ''
                                } as Types.VendorDetail;
                              });
                              setStates([]);
                              if (newCountry?.id) {
                                (async () => {
                                  setStates(await api.getStates(newCountry?.id));
                                })();
                              }
                            }}
                            renderInput={(params) => (
                              <TextField {...params} label="Country" required
                                error={!!formErrors['country_id']} helperText={formErrors['country_id']}
                                inputProps={{ ...params.inputProps, autoComplete: 'new-password', }}
                              />
                            )}
                          />
                          {!!editedVendor && (
                            <SubText text={vendor.country} subText={editedVendor.country} />
                          )}
                        </Stack>

                      </Stack>

                      <Stack spacing={2} direction="row">
                        <Stack sx={{ width: '100%' }}>
                          <TextField label="Latitude" value={vendor.latitude}
                            onChange={updateVendor('latitude')}
                            onBlur={setLatitude} fullWidth />
                          {!!editedVendor && (
                            <SubText text={vendor.latitude} subText={editedVendor.latitude} />
                          )}
                        </Stack>

                        <Stack sx={{ width: '100%' }}>
                          <TextField label="Longitude" value={vendor.longitude}
                            onChange={updateVendor('longitude')}
                            onBlur={setLongitude} fullWidth />
                          {!!editedVendor && (
                            <SubText text={vendor.longitude} subText={editedVendor.longitude} />
                          )}
                        </Stack>
                      </Stack>

                      <Stack spacing={2} alignItems="center">
                        {!!geocodingMsg && (
                          <Typography>{geocodingMsg}</Typography>
                        )}

                        {(!!vendor.latitude || !!vendor.longitude) && (
                          <Typography><a target="blank" style={{color: 'black'}}
                            href={
                              'https://www.google.com/maps/search/?api=1&query=' +
                              vendor.latitude + '%2C' + vendor.longitude
                            }
                          >
                            View map
                          </a></Typography>
                        )}
                      </Stack>

                    </Stack>
                  </Paper>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Paper elevation={3} sx={{ p: 2 }}>
                    <Stack spacing={2}>

                      <Typography variant="button">Vendor Categories</Typography>

                      {vendor.categories.map((vendorCategory, index) => (
                        <Stack key={index} spacing={2} direction="row" alignItems="center">

                          <Typography>{index + 1}</Typography>

                          <Autocomplete id={'category-' + index} fullWidth options={categories}
                            getOptionLabel={(category) => category.name}
                            value={categories.find(c => c.id === vendorCategory.id) || null}
                            onChange={(e: any, newCategory: Types.Category|null) => {
                              setVendor(v => {
                                const newVendorCategories = [ ...(v?.categories || []) ];
                                newVendorCategories[index].id = (newCategory?.id || 0);
                                return { ...v, categories: newVendorCategories } as Types.VendorDetail;
                              });
                            }}
                            renderInput={(params) => (
                              <TextField {...params} label="Category"
                                inputProps={{ ...params.inputProps, autoComplete: 'new-password', }}
                              />
                            )}
                          />

                          <IconButton sx={{ color: 'black' }}
                            onClick={() => setVendor(v => {
                              const newVendorCategories = [ ...(v?.categories || []) ];
                              newVendorCategories.splice(index, 1);
                              return { ...v, categories: newVendorCategories } as Types.VendorDetail;
                            })}
                          >
                            <DeleteIcon />
                          </IconButton>

                        </Stack>
                      ))}

                      {!!editedVendor?.categories && (
                        <Stack>
                          {editedVendor.categories.map((vendorCategory, index) => (
                            <Stack key={index} spacing={2} direction="row" alignItems="center">
                              <SubText subText={index + 1} />
                              <SubText fullWidth subText={vendorCategory.name || ''} />
                              <Stack sx={{ minWidth: 40 }}></Stack>
                            </Stack>
                          ))}
                        </Stack>
                      )}

                      {(!vendor.pending_review || !reviewDeleted) && (
                        <Stack direction="row" justifyContent="center">
                          <Button variant="contained" startIcon={<AddIcon />}
                            onClick={() => setVendor(v => {
                              const newVendorCategories = [ ...(v?.categories || []) ];
                              newVendorCategories.push({ id: 0, name: '' });
                              return { ...v, categories: newVendorCategories } as Types.VendorDetail;
                            })}
                          >
                            Add Category
                          </Button>
                        </Stack>
                      )}

                    </Stack>
                  </Paper>
                </Grid>
              </Grid>

              <SaveSnackbar state={saveState} setState={setSaveState} />
            </>
          )}

          { !vendor && (
            <>
              <div style={{ textAlign: 'center' }}>The vendor was not found</div>
              <Stack direction="row" justifyContent='center' sx={{ pt: 4 }}>
                <BackButton />
              </Stack>
            </>
          )}

        </>
      )}
    </>
  );

};

export default Vendor;
