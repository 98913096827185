import { useEffect, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DeleteIcon from '@mui/icons-material/Delete';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import Loading from '../Loading/Loading';
import UploadSnackbar from './UploadSnackbar';

import api from '../../app/api';
import constants from '../../app/constants';
import * as Types from '../../app/types';

interface ProjectImagesParams {
  projectId: number,
  projectName: string,
  visible: boolean,
  onClose: () => void,
}

const ProjectImages = (params: ProjectImagesParams) => {

  // initialize

  const [status, setStatus] = useState<Types.ComponentState>('loading');
  const [images, setImages] = useState<Types.ProjectImage[]>([]);
  const theme = useTheme();
  const isScreenMd = useMediaQuery(theme.breakpoints.up('md'));
  const isScreenSm = useMediaQuery(theme.breakpoints.up('sm'));

  useEffect(() => {
    (async () => {
      setStatus('loading');
      setImages([]);
      if (!params.visible) { return; }
      if (params.projectId) {
        await syncImages(params.projectId);
      }
      setStatus('complete');
    })();
  }, [params.projectId, params.visible]);

  // make sure the database and storage are in sync

  const syncImages = async (projectId: number) => {
    let changed = false;
    const dbImages = (await api.getProjectImages(projectId)) || [];
    const storedImages = (await api.listProjectImages(projectId)) || [];
    // iterate on dbImages
    const storedNames: {[n: string]: boolean} = {};
    for (const n of storedImages) { storedNames[n] = false; }
    for (let i = 0; i < dbImages.length; i++) {
      const name = dbImages[i].name;
      if (storedNames[name] === false) {
        storedNames[name] = true;
        continue;
      }
      delete dbImages[i];
      changed = true;
    }
    // iterate on storedNames
    for (const name of Object.keys(storedNames)) {
      if (storedNames[name]) { continue; }
      dbImages.push({ name, position: 0 });
      changed = true;
    }
    // update positions and save
    let newDbImages = dbImages;
    if (changed) {
      newDbImages = [];
      let p = 0;
      for (const img of dbImages) {
        if (!img) { continue; }
        img.position = p;
        newDbImages.push(img);
        p++;
      }
      await api.updateProjectImages(projectId, newDbImages);
    }
    setImages(newDbImages);
  };

  // upload images

  const [uploadState, setUploadState] = useState<Types.SaveState>('closed');
  const [numFiles, setNumFiles] = useState<number>(0);
  const [currentFile, setCurrentFile] = useState<number>(0);
  const [numErrors, setNumErrors] = useState<number>(0);

  const onDrop = async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    if (currentFile < numFiles || (!acceptedFiles.length && !fileRejections.length)) { return; }
    if (!acceptedFiles.length) {
      setNumErrors(fileRejections.length);
      setUploadState('error');
      return;
    }
    setUploadState('info');
    setNumErrors(fileRejections.length);
    setNumFiles(acceptedFiles.length);
    const prevImages = images.length;

    for (let i = 0; i < acceptedFiles.length; i++) {
      const file = acceptedFiles[i];
      const reader = new FileReader();
      const imgIndex = i;
      reader.addEventListener('load', async () => {
        const name = await uploadImage(file.type, reader.result!.toString());
        if (name) {
          setImages(imgs => {
            const newImgs = [...imgs];
            newImgs.push({ name, position: prevImages + imgIndex });
            return newImgs;
          });
        } else { setNumErrors(n => n + 1); }
        setCurrentFile(n => n + 1);
      });
      reader.readAsDataURL(file);
    }
  };

  const {getRootProps, getInputProps, open} = useDropzone({
    onDrop, noClick: true, maxFiles: 30, maxSize: 8 * 1024 * 1024,
    disabled: !params.projectId || currentFile < numFiles,
    accept: { 'image/jpeg': [], 'image/png': [], 'image/webp': [] },
  });

  useEffect(() => {
    // on uploads completed
    (async () => {
      if (!numFiles || currentFile < numFiles) { return; }
      // sort images by position
      const newImages = [...images];
      newImages.sort((a, b) => a.position - b.position);
      setImages(newImages);
      // save image names and positions to the database
      const result = await api.updateProjectImages(params.projectId, newImages);
      // update snackbar
      setUploadState(!numErrors && result && !result.error ? 'success' : 'error');
      setNumFiles(0);
      setCurrentFile(0);
    })();
  }, [numFiles, currentFile, images, numErrors, params.projectId]);

  const uploadImage = async (imageType: string, dataUrl: string): Promise<string> => {
    if (dataUrl.length > 8291456) {
      console.log('Payload limit exceeded: ' + dataUrl.length);
      return '';
    }
    // remove prefix from dataUrl
    const token = ';base64,';
    const index = dataUrl.indexOf(token);
    if (index < 0) {
      console.log('Invalid DataURL: ' + dataUrl.substring(0, 25));
      return '';
    }
    const data = dataUrl.substring(index + token.length);
    // upload image
    const name = (new Date()).getTime() + '.' + imageType.replace('image/', '');
    const result = await api.uploadProjectImage(params.projectId, name, data);
    if (result?.error) {
      console.log('Upload error:', result.error);
      return '';
    }
    return name;
  };

  // delete all images

  const deleteAll = async () => {
    setStatus('loading');
    const result = await api.deleteAllProjectImages(params.projectId);
    if (result && !result.error) { setImages([]); }
    else { console.log(result?.error || 'Delete failed'); }
    setStatus('complete');
  };

  // render

  return (
    <>
      <Dialog {...getRootProps({
        open: params.visible, onClose: params.onClose,
        scroll: 'paper', fullWidth: true, maxWidth: 'lg'
      })}>
        <DialogTitle>
          <Stack direction="row" alignItems="center" spacing={isScreenSm ? 4 : 1}>
            <Box sx={{ flexGrow: 1 }}>
              {status === 'complete' && !!params.projectId && (
                <>
                  <Typography sx={{ fontWeight: '700' }} variant={isScreenSm ? 'body1' : 'body2'}>
                    {params.projectName}
                  </Typography>
                  {!isScreenSm && (
                    <Button variant="contained" startIcon={<AddIcon />} onClick={open} sx={{ mt: 1 }}>
                      Add Images
                    </Button>
                  )}
                  <input {...getInputProps()} />
                </>
              )}
            </Box>
            {status === 'complete' && !!params.projectId && isScreenSm && (
              <Box sx={{ flexShrink: 0 }}>
                <Button variant="contained" startIcon={<AddIcon />} onClick={open}>
                  Add Images
                </Button>
              </Box>
            )}
            {status === 'complete' && !!params.projectId && isScreenMd &&
            uploadState !== 'info' && !!images.length && (
              <Box sx={{ flexShrink: 0 }}>
                <Button variant="contained" color="error" startIcon={<DeleteIcon />} onClick={deleteAll}>
                  Delete all
                </Button>
              </Box>
            )}
            <Box sx={{ flexShrink: 0 }}>
              <IconButton sx={{ color: 'black' }} onClick={params.onClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Stack>
        </DialogTitle>
        <DialogContent dividers sx={{ py: 4 }}>
          <>
            { status === 'loading' && (
              <Loading />
            )}
            { status === 'complete' && (
              <div style={{ textAlign: 'center', lineHeight: 0 }}>
                {!!params.projectId && !!images.length && images.map((img, index) => (
                  <div key={index} style={{
                    position: 'relative', display: 'inline-block',
                    margin: '8px', minWidth: '100px', lineHeight: 0,
                    backgroundColor: '#ccc', borderRadius: '4px', overflow: 'hidden',
                  }}>

                    <img alt={img.name} style={{ height: '100px', width: 'auto' }}
                      src={constants.bucketUrl + 'projects/' + params.projectId + '/images/' + img.name} />

                    <IconButton
                      sx={{ color: 'black', width: '40px', position: 'absolute', top: '5px', left: '5px', backgroundColor: 'rgba(255,255,255,0.25)' }}
                      onClick={async () => {
                        if (index === 0 || currentFile < numFiles) { return; }
                        const newImages = [...images];
                        const tPos = newImages[index - 1].position;
                        newImages[index - 1].position = newImages[index].position;
                        newImages[index].position = tPos;
                        const tImg = newImages[index - 1];
                        newImages[index - 1] = newImages[index];
                        newImages[index] = tImg;
                        const result = await api.updateProjectImages(params.projectId, newImages);
                        if (!result || result.error) {
                          console.log('The images could not be updated: ' + (result?.error || typeof result));
                          return;
                        }
                        setImages(newImages);
                      }}
                    >
                      <ChevronLeftIcon />
                    </IconButton>

                    <IconButton
                      sx={{ color: 'black', width: '40px', position: 'absolute', top: '5px', right: '5px', backgroundColor: 'rgba(255,255,255,0.25)' }}
                      onClick={async () => {
                        if (index === images.length - 1 || currentFile < numFiles) { return; }
                        const newImages = [...images];
                        const tPos = newImages[index + 1].position;
                        newImages[index + 1].position = newImages[index].position;
                        newImages[index].position = tPos;
                        const t = newImages[index + 1];
                        newImages[index + 1] = newImages[index];
                        newImages[index] = t;
                        const result = await api.updateProjectImages(params.projectId, newImages);
                        if (!result || result.error) {
                          console.log('The images could not be updated: ' + (result?.error || typeof result));
                          return;
                        }
                        setImages(newImages);
                      }}
                    >
                      <ChevronRightIcon />
                    </IconButton>

                    <IconButton
                      sx={{ color: 'black', width: '40px', position: 'absolute', bottom: '5px', left: '50%', marginLeft: '-20px', backgroundColor: 'rgba(255,255,255,0.25)' }}
                      onClick={async () => {
                        if (currentFile < numFiles) { return; }
                        let result = await api.deleteProjectImage(params.projectId, img.name);
                        if (!result || result.error) {
                          console.log('The file could not be deleted: ' + (result?.error || typeof result));
                          return;
                        }
                        const newImages = [...images];
                        newImages.splice(index, 1);
                        result = await api.updateProjectImages(params.projectId, newImages);
                        if (!result || result.error) {
                          console.log('The images could not be updated: ' + (result?.error || typeof result));
                          return;
                        }
                        setImages(newImages);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>

                  </div>
                ))}
                {!images.length && (
                  <DialogContentText>
                    {params.projectId ? 'Select or drop images (up to 4 MB each)' : 'Please save the project before uploading images'}
                  </DialogContentText>
                )}
              </div>
            )}
          </>
        </DialogContent>
      </Dialog>
      <UploadSnackbar state={uploadState} setState={setUploadState}
        numFiles={numFiles} currentFile={currentFile} numErrors={numErrors} />
    </>
  )
};

export default ProjectImages;
