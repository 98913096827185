import ClearIcon from '@mui/icons-material/Clear';
import Button from '@mui/material/Button';

import constants from '../../app/constants';

interface DeleteParams {
  onClick: () => void,
}

const DeleteButton = (params: DeleteParams) => {
  return (
    <Button variant="contained" color="error" sx={{ minWidth: constants.buttonMinWidth }}
      startIcon={<ClearIcon />} onClick={params.onClick}
    >
      Delete
    </Button>
  );

};

export default DeleteButton;
