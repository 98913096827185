import { SyntheticEvent } from 'react';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import * as Types from '../../app/types';

interface SaveParams {
  state: Types.SaveState,
  setState: (s: Types.SaveState) => any,
}

const SaveSnackbar = (params: SaveParams) => {
  
  const saveClosed = (event?: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') { return; }
    params.setState('closed');
  };

  return (
    <Snackbar open={params.state !== 'closed'}
      autoHideDuration={6000}
      onClose={saveClosed}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      {params.state !== 'closed' ? (
        <Alert sx={{ width: '100%' }} elevation={6} variant="filled" onClose={saveClosed} severity={params.state}>
          {params.state === 'info' ? 'Saving' : (params.state === 'success' ? 'Saved' : 'An error occurred')}
        </Alert>
      ) : (
        <div></div>
      )}
    </Snackbar>
  );

};

export default SaveSnackbar;
