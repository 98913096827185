import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { DataGrid, GridColDef, GridFilterModel, GridSortModel, GridToolbar } from '@mui/x-data-grid';

import ReloadButton from '../ReloadButton/ReloadButton';
import api from '../../app/api';
import constants from '../../app/constants';
import * as Types from '../../app/types';

const Users = () => {

  // define state

  const [users, setUsers] = useState<Types.UserListing[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [rowCount, setRowCount] = useState<number>(0);
  const [gridState, setGridState] = useState<Types.GridState>({
    page: 0, pageSize: constants.pageSizeOptions[0],
    filter: '', sort: '', group: '', pending_review: ''
  });
  const [selectedStatus, setSelectedStatus] = useState<string>('');
  const initDelete = { isOpen: false, userId: '', userEmail: '' };
  const [deleteState, setDeleteState] = useState(initDelete);
  const theme = useTheme();
  const isScreenMd = useMediaQuery(theme.breakpoints.up('md'));
  const isScreenSm = useMediaQuery(theme.breakpoints.up('sm'));

  // load users

  const loadUsers = async (gridState: Types.GridState) => {
    setIsLoading(true);
    const result = await api.getUsers(gridState);
    setUsers(result.users || []);
    setRowCount(result.count || 0);
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => await loadUsers(gridState))();
  }, [gridState]);

  // define grid columns

  const iconsStyle = { color: constants.listIconColor, mx: (isScreenMd ? 1 : 0) };

  const columns: GridColDef[] = [
    {
      field: 'first_name',
      headerName: 'First name',
      flex: 1,
      filterable: false,
      sortable: false,
      hide: !isScreenSm,
    },
    {
      field: 'last_name',
      headerName: 'Last name',
      flex: 1,
      filterable: false,
      sortable: false,
      hide: !isScreenSm,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 2,
    },
    {
      field: 'group',
      headerName: 'Group',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      sortable: false,
      hide: !isScreenSm,
      renderCell: (params) => (params.row.group || 'User'),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      disableExport: true,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <>
          <IconButton component={Link} to={'/users/edit/' + params.row.id} sx={iconsStyle}>
            <EditIcon />
          </IconButton>
          <IconButton sx={iconsStyle} onClick={() => {
            setDeleteState({ isOpen: true, userId: params.row.id, userEmail: params.row.email });
          }}>
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  // render

  const buttonsStyle = { mt: '2px', mx: (isScreenMd ? 4 : 2), mb: 2 };

  return (
    <>
      
      <Box sx={{ textAlign: 'center' }}>
        {isScreenSm && (
          <ReloadButton onClick={async () => await loadUsers(gridState)} sx={buttonsStyle} />
        )}
        
        <FormControl size="small" sx={{ ...buttonsStyle, width: 150, mt: 0 }}>
          <InputLabel id="status-label">Status</InputLabel>
          <Select
            labelId="status-label"
            id="status-select"
            value={selectedStatus}
            label="Status"
            onChange={e => {
              const newStatus = e.target.value;
              setSelectedStatus(newStatus);
              if (newStatus === 'pending_review') {
                setGridState(s => ({ ...s, group: '', pending_review: '1' }));
              } else {
                setGridState(s => ({ ...s, group: newStatus, pending_review: '' }));
              }
            }}
            sx={{ backgroundColor: 'white' }}
          >
            <MenuItem value="">All</MenuItem>
            {constants.userGroups.map(u => (
              <MenuItem key={u} value={u}>{u}</MenuItem>
            ))}
            <MenuItem value="pending_review">Pending review</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <DataGrid
        rows={users}
        columns={columns}
        autoHeight
        rowsPerPageOptions={constants.pageSizeOptions}
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        disableSelectionOnClick
        disableColumnFilter
        disableColumnSelector
        sx={{ boxShadow: 2, backgroundColor: 'white' }}
        paginationMode="server"
        rowCount={rowCount}
        loading={isLoading}
        page={gridState.page}
        onPageChange={page => setGridState(s => ({ ...s, page }))}
        pageSize={gridState.pageSize}
        onPageSizeChange={pageSize => setGridState(s => ({ ...s, pageSize }))}
        filterMode="server"
        onFilterModelChange={(filterModel: GridFilterModel) => setGridState(s => ({
          ...s, filter: (filterModel?.quickFilterValues?.length ? filterModel.quickFilterValues.join(' ') : '')
        }))}
        sortingMode="server"
        onSortModelChange={(sortModel: GridSortModel) => setGridState(s => ({
          ...s, sort: (sortModel?.length ? sortModel[0].sort : '') + ''
        }))}
      />

      <Dialog open={deleteState.isOpen}
        onClose={() => setDeleteState(initDelete)}
        aria-describedby="delete-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="delete-dialog-description" sx={{ color: 'black' }}>
            Delete the user {deleteState.userEmail}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteState(initDelete)} autoFocus>No</Button>
          <Button variant="contained" color="error" onClick={async () => {
            setDeleteState(initDelete);
            setIsLoading(true);
            const result = await api.deleteUser(deleteState.userId);
            if (result && !result.error) { await loadUsers(gridState); }
            else { setIsLoading(false); }
          }}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );

};

export default Users;
