import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { debounce } from 'lodash-es';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import BackButton from '../BackButton/BackButton';
import DeleteButton from '../DeleteButton/DeleteButton';
import Loading from '../Loading/Loading';
import SaveButton from '../SaveButton/SaveButton';
import SaveSnackbar from '../SaveSnackbar/SaveSnackbar';
import SubText from '../SubText/SubText';

import api from '../../app/api';
import constants from '../../app/constants';
import geocoder from '../../app/geocoder';
import * as Types from '../../app/types';
import utils from '../../app/utils';

const emptyProject: Types.ProjectDetail = {
  id: 0, name: '', slug: '', project_type_id: null, gfa: 0, storeys: 0, description: '',
  street_number: '', street_name: '', city: '', state_id: 0, state: '', zip: '',
  country_id: constants.defaultCountryId, country: '', vendors: [],
  latitude: 0, longitude: 0, pending_review: 0, edited_project_id: null, updated_at: '',
};

interface VendorCategories {
  [vendorId: number]: Types.VendorCategory[]|null,
}

const Project = () => {

  // define state

  const [status, setStatus] = useState<Types.ComponentState>('loading');
  const [saveState, setSaveState] = useState<Types.SaveState>('closed');
  const [project, setProject] = useState<Types.ProjectDetail|null>(null);
  const [isNew, setIsNew] = useState<boolean>(false);
  const [editedProject, setEditedProject] = useState<Types.ProjectDetail|null>(null);
  const [reviewDeleted, setReviewDeleted] = useState<boolean>(false);
  const [geocodingMsg, setGeocodingMsg] = useState<string>('');
  const [initialAddress, setInitialAddress] = useState<string>('');
  const urlParams = useParams();

  const [projectTypes, setProjectTypes] = useState<Types.ProjectType[]>([]);
  const [countries, setCountries] = useState<Types.Country[]>([]);
  const [states, setStates] = useState<Types.State[]>([]);
  const [vendorOptions, setVendorOptions] = useState<Types.VendorAutocomplete[]>([]);
  const [changedVendorIndex, setChangedVendorIndex] = useState<number>(-1);
  const [vendorCategories, setVendorCategories] = useState<VendorCategories>({});

  const theme = useTheme();
  const isScreenSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isScreenMd = useMediaQuery(theme.breakpoints.up('md'));

  // initialize

  useEffect(() => {
    (async () => {
      const loadedProject = urlParams.id
        ? await api.getProject(urlParams.id)
        : emptyProject;
      if (loadedProject) {
        const newVendorOptions = [];
        const newVendorCategories: VendorCategories = {};
        for (const v of loadedProject.vendors) {
          newVendorOptions.push({
            input: v.name,
            loading: false,
            options: [{ id: v.vendor_id, name: v.name }]
          } as Types.VendorAutocomplete);
          newVendorCategories[v.vendor_id] = v.categories || [];
        }
        setVendorOptions(newVendorOptions);
        setVendorCategories(newVendorCategories);
        if (!loadedProject.id) {
          loadedProject.updated_at = (new Date()).toISOString();
        }
        const date = new Date(loadedProject.updated_at);
        setIsNew(!!date.getTime() && (new Date()).getTime() - date.getTime() < 3600000);
        setProject(loadedProject);
        setProjectTypes(await api.getProjectTypes());
        setCountries(await api.getCountries());
        setStates(await api.getStates(loadedProject.country_id));
        if (loadedProject.edited_project_id) {
          setEditedProject(await api.getProject(loadedProject.edited_project_id));
        }
      }
      await geocoder.load();
      setInitialAddress(geocoder.addressToString(loadedProject));
      setStatus('complete');
    })();
  }, [urlParams]);

  // vendors autocomplete

  const getVendors = useMemo(() => {
    return debounce(
      async (
        filter: string,
        callback: (result: any) => void,
      ) => {
        const result = await api.getVendors({
          page: 0, pageSize: 10, filter, sort: 'asc', pending_review: '0'
        } as Types.GridState);
        callback(result);
      },
      500,
    );
  }, []);

  useEffect(() => {
    if (changedVendorIndex < 0) { return; }
    const i = changedVendorIndex;
    setChangedVendorIndex(-1);
    const input = vendorOptions[i].input;
    const selectedVendorId = (project?.vendors || [])[i]?.vendor_id;
    if (!input || selectedVendorId) {
      setVendorOptions(o => {
        const newVendorOptions = [ ...o ];
        newVendorOptions[i].loading = false;
        return newVendorOptions;
      });
      return;
    }
    getVendors(input, (result: any) => {
      setVendorOptions(o => {
        const newVendorOptions = [ ...o ];
        newVendorOptions[i].loading = false;
        newVendorOptions[i].options = result.vendors || [];
        return newVendorOptions;
      });
    });
  }, [changedVendorIndex, vendorOptions, project, getVendors]);

  // get vendor categories

  useEffect(() => {
    (async () => {
      if (!project?.vendors?.length) { return; }
      const newVendorCategories = { ...vendorCategories };
      let updated = false;
      for (const v of project.vendors) {
        if (!v.vendor_id || vendorCategories[v.vendor_id]) { continue; }
        const vCategories: Types.VendorCategory[] = (await api.getVendorCategories(v.vendor_id)) || [];
        newVendorCategories[v.vendor_id] = vCategories;
        updated = true;
      }
      if (updated) { setVendorCategories(newVendorCategories); }
    })();
  }, [project?.vendors, vendorCategories]);

  // define validation

  const [formErrors, setFormErrors] = useState<Types.FormErrors>({});

  const updateProject = (field: string) => (e: any) => {
    setFormErrors(e => ({ ...e, [field]: '' }));
    setProject(p => ({ ...p, [field]: e.target.value } as Types.ProjectDetail));
  };

  const setPositiveInt = (field: string) => (e: any) => {
    const value = Math.max(0, parseInt(e.target.value) || 0);
    setProject(p => ({ ...p, [field]: value } as Types.ProjectDetail));
  };

  const isValid = (project: Types.ProjectDetail): boolean => {
    if (!project) { return false; }
    const errors: Types.FormErrors = {};
    if (!project.name.trim()) {
      errors.name = 'Please enter a name';
    }
    if (!project.state_id) {
      errors.state_id = 'Please select a state or province';
    }
    if (!project.country_id) {
      errors.country_id = 'Please select a country';
    }
    if (!project.updated_at) {
      errors.updated_at = 'Please enter a valid time';
    }
    setFormErrors(errors);
    return !Object.keys(errors).length;
  };

  // render

  return (
    <>
      { status === 'loading' && (
        <Loading />
      )}
      { status === 'complete' && (
        <>

          { !!project && (
            <>
              {!!project.pending_review && !reviewDeleted && (
                <Alert icon={false} sx={{ mb: 2 }}
                  severity={project.edited_project_id ? 'info' : 'success'}
                >
                  {'Pending review: ' + (editedProject
                    ? 'Edits project "' + editedProject.name +'"'
                    : 'New project'
                  )}
                  {project.updated_by && project.user_email && (
                    <span>. Submitted by &nbsp;
                      <a href={constants.adminUrl + 'users/edit/' + project.updated_by}
                      target="_blank" rel="noreferrer">
                        {project.user_email}
                      </a>
                    </span>
                  )}
                </Alert>
              )}

              <Stack direction="row" sx={{ pb: 2 }} spacing={4} justifyContent="center">
                <Box>
                  <BackButton />
                </Box>

                {(!project.pending_review || !reviewDeleted) && (
                  <Box>
                    <SaveButton
                      label={project.pending_review ? 'Approve' : 'Save'}
                      onClick={async () => {
                        // validate
                        if (saveState === 'info' || !isValid(project)) { return; }
                        setSaveState('info');
                        const p = { ...project } as Types.ProjectDetail;
                        p.country = countries.find(c => c.id === p.country_id)?.name || '';
                        // geocode
                        const newAddress = geocoder.addressToString(p);
                        if (newAddress !== initialAddress || (!p.latitude && !p.longitude)) {
                          setGeocodingMsg('Geocoding...');
                          const gResult = await geocoder.geocode(p);
                          setGeocodingMsg(gResult.error);
                          if (gResult && !gResult.error) {
                            p.latitude = gResult.location.latitude;
                            p.longitude = gResult.location.longitude;
                            setInitialAddress(newAddress);
                          }
                        }
                        // save project
                        let result: any;
                        if (p.pending_review) {
                          p.pending_review = 0;
                          if (p.edited_project_id) {
                            p.id = p.edited_project_id;
                            p.edited_project_id = null;
                          }
                        }
                        if (p.id) {
                          result = await api.updateProject(p);
                        } else {
                          result = await api.createProject(p);
                        }
                        // process result
                        if (result && !result.error && result.id) {
                          if (project.pending_review && project.edited_project_id) {
                            await api.deleteProject(project.id);  // the edit was applied to edited_project_id
                          }
                          p.id = result.id;  // no change unless the project is new
                          setProject(p);
                          setSaveState('success');
                        } else {
                          if (result?.error?.field && result.error.message) {
                            setFormErrors(e => ({ ...e, [result.error.field]: result.error.message }));
                          }
                          setSaveState('error');
                        }
                      }}
                    />
                  </Box>
                )}

                {!!project.pending_review && !reviewDeleted && (
                  <Box>
                    <DeleteButton onClick={async () => {
                      setSaveState('info');
                      await api.deleteProject(project.id);
                      setSaveState('success');
                      setReviewDeleted(true);
                    }} />
                  </Box>
                )}
              </Stack>

              <Grid container spacing={4}>

                <Grid item xs={12} lg={6}>
                  <Paper elevation={3} sx={{ p: 2 }}>
                    <Stack spacing={2}>

                      <Typography variant="button">Project Details</Typography>

                      <Stack sx={{ width: '100%' }}>
                        <TextField label="Name" value={project.name} required
                          error={!!formErrors['name']} helperText={formErrors['name']}
                          onChange={updateProject('name')} fullWidth />
                        {!!editedProject?.name && (
                          <SubText text={project.name} subText={editedProject.name} />
                        )}
                      </Stack>

                      <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }}>
                        <Stack sx={{ width: '100%' }}>
                          <TextField label="Slug" value={project.slug}
                            error={!!formErrors['slug']} helperText={formErrors['slug']}
                            onChange={updateProject('slug')} fullWidth />
                          {!!editedProject && (
                            <SubText text={project.slug} subText={editedProject.slug} />
                          )}
                        </Stack>

                        <Stack sx={{ width: '100%' }}>
                          <Autocomplete id="project_type" fullWidth options={projectTypes}
                            getOptionLabel={(projectType) => projectType.name}
                            value={projectTypes.find(t => t.id === project.project_type_id) || null}
                            onChange={(e: any, newType: Types.ProjectType|null) => {
                              setProject(p => {
                                return { ...p, project_type_id: (newType?.id || 0) } as Types.ProjectDetail;
                              });
                            }}
                            renderInput={(params) => (
                              <TextField {...params} label="Project Type"
                                inputProps={{ ...params.inputProps, autoComplete: 'new-password', }}
                              />
                            )}
                          />
                          {!!editedProject && (
                            <SubText text={
                              projectTypes.find(t => t.id === project.project_type_id)?.name || ''
                            } subText={
                              projectTypes.find(t => t.id === editedProject.project_type_id)?.name || ''
                            } />
                          )}
                        </Stack>
                      </Stack>

                      <Stack spacing={2} direction="row">
                        <Stack sx={{ width: '100%' }}>
                          <TextField label="GFA (square feet)" value={project.gfa}
                            onChange={updateProject('gfa')}
                            onBlur={setPositiveInt('gfa')} fullWidth />
                          {!!editedProject && (
                            <SubText text={project.gfa.toLocaleString()} subText={editedProject.gfa.toLocaleString()} />
                          )}
                        </Stack>

                        <Stack sx={{ width: '100%' }}>
                          <TextField label="Storeys" value={project.storeys}
                            onChange={updateProject('storeys')}
                            onBlur={setPositiveInt('storeys')} fullWidth />
                          {!!editedProject && (
                            <SubText text={project.storeys} subText={editedProject.storeys} />
                          )}
                        </Stack>
                      </Stack>

                      <Stack sx={{ width: '100%' }}>
                        <TextField label="Description" value={project.description}
                          onChange={updateProject('description')} fullWidth multiline />
                        {!!editedProject?.description && (
                          <SubText text={project.description} subText={editedProject.description} />
                        )}
                      </Stack>

                      <Stack spacing={2} direction="row">
                        <Stack sx={{ width: '100%' }}>
                          <TextField label="Street Number" value={project.street_number}
                            onChange={updateProject('street_number')} fullWidth />
                          {!!editedProject && (
                            <SubText text={project.street_number} subText={editedProject.street_number} />
                          )}
                        </Stack>

                        <Stack sx={{ width: '100%' }}>
                          <TextField label="Street Name" value={project.street_name}
                            onChange={updateProject('street_name')} fullWidth />
                          {!!editedProject && (
                            <SubText text={project.street_name} subText={editedProject.street_name} />
                          )}
                        </Stack>
                      </Stack>

                      <Stack spacing={2} direction="row">
                        <Stack sx={{ width: '100%' }}>
                          <TextField label="City" value={project.city}
                            onChange={updateProject('city')} fullWidth />
                          {!!editedProject && (
                            <SubText text={project.city} subText={editedProject.city} />
                          )}
                        </Stack>

                        <Stack sx={{ width: '100%' }}>
                          <TextField label="Postal Code / Zip" value={project.zip}
                            onChange={updateProject('zip')} fullWidth />
                          {!!editedProject && (
                            <SubText text={project.zip} subText={editedProject.zip} />
                          )}
                        </Stack>
                      </Stack>

                      <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }}>

                        <Stack sx={{ width: '100%' }}>
                          <Autocomplete id="state" fullWidth options={states}
                            getOptionLabel={(state) => state.name}
                            value={states.find(s => s.id === project.state_id) || null}
                            onChange={(e: any, newState: Types.State|null) => {
                              setFormErrors(e => ({ ...e, state_id: '' }));
                              setProject(p => {
                                return {
                                  ...p, state_id: (newState?.id || 0),
                                  state: states.find(s => s.id === newState?.id)?.name || ''
                                } as Types.ProjectDetail;
                              });
                            }}
                            renderInput={(params) => (
                              <TextField {...params} label="State / Province" required
                                error={!!formErrors['state_id']} helperText={formErrors['state_id']}
                                inputProps={{ ...params.inputProps, autoComplete: 'new-password', }}
                              />
                            )}
                          />
                          {!!editedProject && (
                            <SubText text={project.state} subText={editedProject.state} />
                          )}
                        </Stack>

                        <Stack sx={{ width: '100%' }}>
                          <Autocomplete id="country" fullWidth options={countries}
                            getOptionLabel={(country) => country.name}
                            value={countries.find(c => c.id === project.country_id) || null}
                            onChange={(e: any, newCountry: Types.Country|null) => {
                              setFormErrors(e => ({ ...e, country_id: '' }));
                              setProject(p => {
                                return {
                                  ...p, country_id: (newCountry?.id || 0), state_id: 0, state: '',
                                  country: countries.find(c => c.id === newCountry?.id)?.name || ''
                                } as Types.ProjectDetail;
                              });
                              setStates([]);
                              if (newCountry?.id) {
                                (async () => {
                                  setStates(await api.getStates(newCountry?.id));
                                })();
                              }
                            }}
                            renderInput={(params) => (
                              <TextField {...params} label="Country" required
                                error={!!formErrors['country_id']} helperText={formErrors['country_id']}
                                inputProps={{ ...params.inputProps, autoComplete: 'new-password', }}
                              />
                            )}
                          />
                          {!!editedProject && (
                            <SubText text={project.country} subText={editedProject.country} />
                          )}
                        </Stack>

                      </Stack>

                      <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }}>
                        <Stack sx={{ width: '100%' }}>
                          <TextField type="datetime-local" label="Last Updated"
                            error={!!formErrors['updated_at']} helperText={formErrors['updated_at']}
                            sx={{ maxWidth: '100%', width: '100%' }}
                            InputLabelProps={{ shrink: true }}
                            value={utils.isoToLocal(project.updated_at)}
                            onChange={updateProject('updated_at')}
                            onBlur={e => {
                              const date = new Date(e.target.value);
                              const value = (date.getTime() ? date.toISOString() : '');
                              setProject(p => ({ ...p, 'updated_at': value } as Types.ProjectDetail));
                              setIsNew(!!date.getTime() && (new Date()).getTime() - date.getTime() < 3600000);
                            }} />
                          {!!editedProject && (
                            <SubText text={utils.isoToLocal(project.updated_at)}
                              subText={utils.isoToLocal(editedProject.updated_at)} />
                          )}
                        </Stack>

                        <Stack sx={{ width: '100%' }}>
                          <FormControlLabel label="Updated Now" sx={{ flex: 1 }}
                            control={<Switch checked={isNew}
                              onChange={e => {
                                setIsNew(e.target.checked);
                                const now = new Date();
                                const oldDate = new Date();
                                oldDate.setFullYear(2020);
                                setProject(p => ({
                                  ...p, 'updated_at': (e.target.checked ? now : oldDate).toISOString()
                                } as Types.ProjectDetail));
                              }}
                            />}
                          />
                        </Stack>
                      </Stack>

                      <Stack spacing={2} direction="row">
                        <Stack sx={{ width: '100%' }}>
                          <TextField label="Latitude" value={project.latitude || ''}
                            onChange={updateProject('latitude')}
                            onBlur={e => {
                              const value = utils.getLatitude(e.target.value);
                              setProject(p => ({ ...p, 'latitude': value } as Types.ProjectDetail));
                            }} fullWidth />
                          {!!editedProject && (
                            <SubText text={project.latitude} subText={editedProject.latitude || ''} />
                          )}
                        </Stack>

                        <Stack sx={{ width: '100%' }}>
                          <TextField label="Longitude" value={project.longitude || ''}
                            onChange={updateProject('longitude')}
                            onBlur={e => {
                              const value = utils.getLongitude(e.target.value);
                              setProject(p => ({ ...p, 'longitude': value } as Types.ProjectDetail));
                            }} fullWidth />
                          {!!editedProject && (
                            <SubText text={project.longitude} subText={editedProject.longitude || ''} />
                          )}
                        </Stack>
                      </Stack>

                      <Stack spacing={2} alignItems="center">
                        {!!geocodingMsg && (
                          <Typography>{geocodingMsg}</Typography>
                        )}

                        {(!!project.latitude || !!project.longitude) && (
                          <Typography><a target="blank" style={{color: 'black'}}
                            href={
                              'https://www.google.com/maps/search/?api=1&query=' +
                              project.latitude + '%2C' + project.longitude
                            }
                          >
                            View map
                          </a></Typography>
                        )}
                      </Stack>

                    </Stack>
                  </Paper>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <Paper elevation={3} sx={{ p: 2 }}>
                    <Stack spacing={isScreenSm ? 2 : 3}>

                      <Typography variant="button">Project Vendors</Typography>

                      {project.vendors.map((projectVendor, index) => (
                        <Stack key={index} spacing={2} direction="row" alignItems="center">

                          {isScreenMd && (
                            <Typography>{index + 1}</Typography>
                          )}

                          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ width: '100%' }}>
                            <Autocomplete id={'vendor-' + index} fullWidth options={vendorOptions[index].options}
                              getOptionLabel={(vendor) => vendor.name}
                              value={vendorOptions[index].options.find(v => v.id === projectVendor.vendor_id) || null}
                              noOptionsText={!vendorOptions[index].input ? 'Type a vendor name' : 'No options'}
                              loading={vendorOptions[index].loading}
                              filterOptions={v => v}
                              onInputChange={(e: any, newVendorInput) => {
                                setVendorOptions(o => {
                                  const newVendorOptions = [ ...o ];
                                  newVendorOptions[index].input = newVendorInput;
                                  newVendorOptions[index].loading = true;
                                  return newVendorOptions;
                                });
                                setChangedVendorIndex(index);
                              }}
                              onChange={(e: any, newVendor: Types.VendorListing|null) => {
                                setProject(p => {
                                  const newProjectVendors = [ ...(p?.vendors || []) ];
                                  newProjectVendors[index].vendor_id = (newVendor?.id || 0);
                                  newProjectVendors[index].category_id = 0;
                                  return { ...p, vendors: newProjectVendors } as Types.ProjectDetail;
                                });
                              }}
                              renderInput={(params) => (
                                <TextField {...params} label="Vendor"
                                  inputProps={{ ...params.inputProps, autoComplete: 'new-password', }}
                                />
                              )}
                            />

                            <Autocomplete id={'category-' + index} fullWidth
                              options={vendorCategories[projectVendor.vendor_id] || []}
                              getOptionLabel={(category) => category.name}
                              value={(vendorCategories[projectVendor.vendor_id] || []).find(
                                c => c.id === projectVendor.category_id
                              ) || null}
                              onChange={(e: any, newCategory: Types.VendorCategory|null) => {
                                setProject(p => {
                                  const newProjectVendors = [ ...(p?.vendors || []) ];
                                  newProjectVendors[index].category_id = (newCategory?.id || 0);
                                  return { ...p, vendors: newProjectVendors } as Types.ProjectDetail;
                                });
                              }}
                              renderInput={(params) => (
                                <TextField {...params} label="Category"
                                  inputProps={{ ...params.inputProps, autoComplete: 'new-password', }}
                                />
                              )}
                            />
                          </Stack>

                          <IconButton sx={{ color: 'black' }}
                            onClick={() => {
                              setProject(p => {
                                const newProjectVendors = [ ...(p?.vendors || []) ];
                                newProjectVendors.splice(index, 1);
                                return { ...p, vendors: newProjectVendors } as Types.ProjectDetail;
                              });
                              setVendorOptions(o => {
                                const newVendorOptions = [ ...o ];
                                newVendorOptions.splice(index, 1);
                                return newVendorOptions;
                              });
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>

                        </Stack>
                      ))}

                      {!!editedProject?.vendors && (
                        <Stack>
                          {editedProject.vendors.map((projectVendor, index) => (
                            <Stack key={index} spacing={2} direction="row" alignItems="center">
                              <SubText subText={index + 1} />
                              <SubText fullWidth subText={projectVendor.name || ''} />
                              <SubText fullWidth subText={projectVendor.category || ''} />
                              <Stack sx={{ minWidth: 40 }}></Stack>
                            </Stack>
                          ))}
                        </Stack>
                      )}

                      {(!project.pending_review || !reviewDeleted) && (
                        <Stack direction="row" justifyContent="center">
                          <Button variant="contained" startIcon={<AddIcon />}
                            onClick={() => {
                              setProject(p => {
                                const newProjectVendors = [ ...(p?.vendors || []) ];
                                newProjectVendors.push({ vendor_id: 0, category_id: 0 });
                                return { ...p, vendors: newProjectVendors } as Types.ProjectDetail;
                              });
                              setVendorOptions(o => {
                                const newVendorOptions = [ ...o ];
                                newVendorOptions.push({ input: '', loading: false, options: [] });
                                return newVendorOptions;
                              });
                            }}
                          >
                            Add Vendor
                          </Button>
                        </Stack>
                      )}

                    </Stack>
                  </Paper>
                </Grid>
              </Grid>

              <SaveSnackbar state={saveState} setState={setSaveState} />
            </>
          )}

          { !project && (
            <>
              <div style={{ textAlign: 'center' }}>The project was not found</div>
              <Stack direction="row" justifyContent='center' sx={{ pt: 4 }}>
                <BackButton />
              </Stack>
            </>
          )}

        </>
      )}
    </>
  );

};

export default Project;
