import { Routes, Route } from 'react-router-dom';
import Container from '@mui/material/Container';

import Categories from '../Categories/Categories';
import Category from '../Category/Category';
import CategoryGroup from '../CategoryGroup/CategoryGroup';
import CategoryGroups from '../CategoryGroups/CategoryGroups';
import BatchGeocoding from '../BatchGeocoding/BatchGeocoding';
import Home from '../Home/Home';
import Job from '../Job/Job';
import Jobs from '../Jobs/Jobs';
import JobApps from '../JobApps/JobApps';
import Login from '../Login/Login';
import Project from '../Project/Project';
import Projects from '../Projects/Projects';
import ProjectType from '../ProjectType/ProjectType';
import ProjectTypes from '../ProjectTypes/ProjectTypes';
import User from '../User/User';
import Users from '../Users/Users';
import Vendors from '../Vendors/Vendors';
import Vendor from '../Vendor/Vendor';
import SlackInvites from '../SlackInvites/SlackInvites';

const Content = () => {

  return (
    <Container sx={{ py: 4 }}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="batch-geocoding" element={<BatchGeocoding />} />
        <Route path="categories" element={<Categories />} />
        <Route path="categories/new" element={<Category />} />
        <Route path="categories/edit/:slug" element={<Category />} />
        <Route path="category-groups" element={<CategoryGroups />} />
        <Route path="category-groups/new" element={<CategoryGroup />} />
        <Route path="category-groups/edit/:slug" element={<CategoryGroup />} />
        <Route path="jobs" element={<Jobs />} />
        <Route path="jobs/new" element={<Job />} />
        <Route path="jobs/edit/:id" element={<Job />} />
        <Route path="job-apps" element={<JobApps />} />
        <Route path="log-in" element={<Login />} />
        <Route path="projects" element={<Projects />} />
        <Route path="projects/new" element={<Project />} />
        <Route path="projects/edit/:id" element={<Project />} />
        <Route path="project-types" element={<ProjectTypes />} />
        <Route path="project-types/new" element={<ProjectType />} />
        <Route path="project-types/edit/:slug" element={<ProjectType />} />
        <Route path="users" element={<Users />} />
        <Route path="users/new" element={<User />} />
        <Route path="users/edit/:id" element={<User />} />
        <Route path="vendors" element={<Vendors />} />
        <Route path="vendors/new" element={<Vendor />} />
        <Route path="vendors/edit/:id" element={<Vendor />} />
        <Route path="slack-invites" element={<SlackInvites />} />
      </Routes>
    </Container>
  );
};

export default Content;
