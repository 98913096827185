import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { debounce } from 'lodash-es';

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import BackButton from '../BackButton/BackButton';
import Loading from '../Loading/Loading';
import SaveButton from '../SaveButton/SaveButton';
import SaveSnackbar from '../SaveSnackbar/SaveSnackbar';

import api from '../../app/api';
import constants from '../../app/constants';
import * as Types from '../../app/types';

const emptyJob: Types.JobDetail = {
  id: 0, title: '', slug: '', vendor_id: 0, vendor: '', updated_at: '', created_at: '',
  description: '', requirements: '', min_salary: 0, max_salary: 0, city: '',
  state_id: 0, state: '', country_id: constants.defaultCountryId, country: '',
};

const Job = () => {

  // define state

  const [status, setStatus] = useState<Types.ComponentState>('loading');
  const [saveState, setSaveState] = useState<Types.SaveState>('closed');
  const [job, setJob] = useState<Types.JobDetail|null>(null);
  const urlParams = useParams();

  const [countries, setCountries] = useState<Types.Country[]>([]);
  const [states, setStates] = useState<Types.State[]>([]);
  const [vendorState, setVendorState] = useState<Types.VendorAutocomplete>({ input: '', loading: false, options: [] });

  // initialize

  useEffect(() => {
    (async () => {
      const loadedJob = urlParams.id
        ? await api.getJob(urlParams.id)
        : emptyJob;
      if (loadedJob) {
        if (loadedJob.vendor_id) {
          setVendorState(a => ({...a, options: [
            { id: loadedJob.vendor_id, name: loadedJob.vendor }
          ]}));
        }
        setJob(loadedJob);
        setCountries(await api.getCountries());
        setStates(await api.getStates(loadedJob.country_id));
      }
      setStatus('complete');
    })();
  }, [urlParams]);

  // vendor autocomplete

  const getVendors = useMemo(() => {
    return debounce(
      async (
        filter: string,
        callback: (result: any) => void,
      ) => {
        const result = await api.getVendors({
          page: 0, pageSize: 10, filter, sort: 'asc', pending_review: '0'
        });
        callback(result);
      },
      500,
    );
  }, []);

  useEffect(() => {
    if (!vendorState.loading) { return; }
    const input = vendorState.input.trim();
    if (!input) {
      setVendorState(s => ({ ...s, loading: false, options: [] }));
      return;
    }
    getVendors(input, (result: any) => {
      setVendorState(s => ({ ...s, loading: false, options: result.vendors || [] }));
    });
  }, [vendorState, getVendors]);

  // define validation

  const [formErrors, setFormErrors] = useState<Types.FormErrors>({});

  const updateJob = (field: string) => (e: any) => {
    setFormErrors(e => ({ ...e, [field]: '' }));
    setJob(j => ({ ...j, [field]: e.target.value } as Types.JobDetail));
  };

  const setPositiveInt = (field: string) => (e: any) => {
    const value = Math.max(0, parseInt(e.target.value) || 0);
    setJob(j => ({ ...j, [field]: value } as Types.JobDetail));
  };

  const isValid = (job: Types.JobDetail): boolean => {
    if (!job) { return false; }
    const errors: Types.FormErrors = {};
    if (!job.title.trim()) {
      errors.title = 'Please enter a title';
    }
    if (!job.vendor_id) {
      errors.vendor_id = 'Please select a vendor';
    }
    if (!job.description.trim()) {
      errors.description = 'Please enter the job description';
    }
    if (!job.requirements.trim()) {
      errors.requirements = 'Please enter the job requirements';
    }
    if (job.max_salary < job.min_salary) {
      errors.max_salary = 'Should be greater than min salary';
    }
    if (!job.state_id) {
      errors.state_id = 'Please select a state or province';
    }
    if (!job.country_id) {
      errors.country_id = 'Please select a country';
    }
    setFormErrors(errors);
    return !Object.keys(errors).length;
  };

  // render

  return (
    <>
      { status === 'loading' && (
        <Loading />
      )}
      { status === 'complete' && (
        <>

          { !!job && (
            <>

              <Stack direction="row" sx={{ pb: 2 }} spacing={4} justifyContent="center">
                <Box>
                  <BackButton />
                </Box>
                <Box>
                  <SaveButton onClick={async () => {
                    if (saveState === 'info' || !isValid(job)) { return; }
                    setSaveState('info');
                    const result = await (job?.id ? api.updateJob(job) : api.createJob(job));
                    if (result && !result.error) {
                      if (!job?.id) { setJob(emptyJob); }
                      setSaveState('success');
                    } else {
                      if (result?.error?.field && result.error.message) {
                        setFormErrors(e => ({ ...e, [result.error.field]: result.error.message }));
                      }
                      setSaveState('error');
                    }
                  }} />
                </Box>
              </Stack>

              <Grid container spacing={4} justifyContent="center">

                <Grid item xs={12} md={6}>
                  <Paper elevation={3} sx={{ p: 2 }}>
                    <Stack spacing={2}>

                      <Typography variant="button">Job Details</Typography>

                      <TextField label="Title" value={job.title} required
                        error={!!formErrors['title']} helperText={formErrors['title']}
                        onChange={updateJob('title')} fullWidth />

                      <Stack spacing={2} direction="row">
                        <TextField label="Min Salary" value={job.min_salary}
                          error={!!formErrors['min_salary']} helperText={formErrors['min_salary']}
                          onChange={updateJob('min_salary')}
                          onBlur={setPositiveInt('min_salary')} fullWidth />

                        <TextField label="Max Salary" value={job.max_salary}
                          error={!!formErrors['max_salary']} helperText={formErrors['max_salary']}
                          onChange={updateJob('max_salary')}
                          onBlur={setPositiveInt('max_salary')} fullWidth />
                      </Stack>

                      <Stack spacing={2} direction="row">
                        <Autocomplete id="job-vendor" fullWidth options={vendorState.options}
                          getOptionLabel={o => o.name}
                          value={vendorState.options.find(v => v.id === job.vendor_id) || null}
                          noOptionsText={!vendorState.input ? 'Type a vendor name' : 'No options'}
                          loading={vendorState.loading}
                          filterOptions={o => o}
                          onInputChange={(e: any, input, reason) => {
                            if (reason !== 'input') { return; }
                            setVendorState(s => ({ ...s, input, loading: true }));
                          }}
                          onChange={(e: any, newVendor: Types.VendorListing|null) => {
                            setJob(j => ({...j, vendor_id: newVendor?.id || null} as Types.JobDetail));
                          }}
                          renderInput={(params) => (
                            <TextField {...params} label="Vendor" required
                              error={!!formErrors['vendor_id']} helperText={formErrors['vendor_id']}
                              inputProps={{ ...params.inputProps, autoComplete: 'new-password', }}
                            />
                          )}
                        />

                        <TextField label="City" value={job.city}
                          onChange={updateJob('city')} fullWidth />
                      </Stack>

                      <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }}>

                        <Autocomplete id="state" fullWidth options={states}
                          getOptionLabel={(state) => state.name}
                          value={states.find(s => s.id === job.state_id) || null}
                          onChange={(e: any, newState: Types.State|null) => {
                            setFormErrors(e => ({ ...e, state_id: '' }));
                            setJob(j => {
                              return {
                                ...j, state_id: (newState?.id || 0),
                                state: states.find(s => s.id === newState?.id)?.name || ''
                              } as Types.JobDetail;
                            });
                          }}
                          renderInput={(params) => (
                            <TextField {...params} label="State / Province" required
                              error={!!formErrors['state_id']} helperText={formErrors['state_id']}
                              inputProps={{ ...params.inputProps, autoComplete: 'new-password', }}
                            />
                          )}
                        />

                        <Autocomplete id="country" fullWidth options={countries}
                          getOptionLabel={(country) => country.name}
                          value={countries.find(c => c.id === job.country_id) || null}
                          onChange={(e: any, newCountry: Types.Country|null) => {
                            setFormErrors(e => ({ ...e, country_id: '' }));
                            setJob(j => {
                              return {
                                ...j, country_id: (newCountry?.id || 0), state_id: 0, state: '',
                                country: countries.find(c => c.id === newCountry?.id)?.name || ''
                              } as Types.JobDetail;
                            });
                            setStates([]);
                            if (newCountry?.id) {
                              (async () => {
                                setStates(await api.getStates(newCountry?.id));
                              })();
                            }
                          }}
                          renderInput={(params) => (
                            <TextField {...params} label="Country" required
                              error={!!formErrors['country_id']} helperText={formErrors['country_id']}
                              inputProps={{ ...params.inputProps, autoComplete: 'new-password', }}
                            />
                          )}
                        />

                      </Stack>

                      <TextField label="Description" value={job.description} required
                        error={!!formErrors['description']} helperText={formErrors['description']}
                        onChange={updateJob('description')} fullWidth multiline />

                      <TextField label="Requirements" value={job.requirements} required
                        error={!!formErrors['requirements']} helperText={formErrors['requirements']}
                        onChange={updateJob('requirements')} fullWidth multiline />

                    </Stack>
                  </Paper>
                </Grid>
              </Grid>

              <SaveSnackbar state={saveState} setState={setSaveState} />
            </>
          )}

          { !job && (
            <>
              <div style={{ textAlign: 'center' }}>The job was not found</div>
              <Stack direction="row" justifyContent='center' sx={{ pt: 4 }}>
                <BackButton />
              </Stack>
            </>
          )}

        </>
      )}
    </>
  );

};

export default Job;
