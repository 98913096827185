import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import BackButton from '../BackButton/BackButton';
import Loading from '../Loading/Loading';
import SaveButton from '../SaveButton/SaveButton';
import SaveSnackbar from '../SaveSnackbar/SaveSnackbar';

import api from '../../app/api';
import * as Types from '../../app/types';

const emptyProjectType: Types.ProjectType = {
  id: 0, name: '', slug: ''
};

const ProjectType = () => {

  // define state

  const [status, setStatus] = useState<Types.ComponentState>('loading');
  const [saveState, setSaveState] = useState<Types.SaveState>('closed');
  const [projectType, setProjectType] = useState<Types.ProjectType|null>(null);
  const urlParams = useParams();

  // initialize

  useEffect(() => {
    (async () => {
      const loadedProjectType = urlParams.slug
        ? await api.getProjectType(urlParams.slug)
        : emptyProjectType;
      if (loadedProjectType) {
        setProjectType(loadedProjectType);
      }
      setStatus('complete');
    })();
  }, [urlParams]);

  // define validation

  const [formErrors, setFormErrors] = useState<Types.FormErrors>({});

  const updateProjectType = (field: string) => (e: any) => {
    setFormErrors(e => ({ ...e, [field]: '' }));
    setProjectType(g => ({ ...g, [field]: e.target.value } as Types.ProjectType));
  };

  const isValid = (projectType: Types.ProjectType): boolean => {
    if (!projectType) { return false; }
    const errors: Types.FormErrors = {};
    if (!projectType.name.trim()) {
      errors.name = 'Please enter a name';
    }
    setFormErrors(errors);
    return !Object.keys(errors).length;
  };

  // render

  return (
    <>
      { status === 'loading' && (
        <Loading />
      )}
      { status === 'complete' && (
        <>

          { !!projectType && (
            <>

              <Stack direction="row" sx={{ pb: 2 }} spacing={4} justifyContent="center">
                <Box>
                  <BackButton />
                </Box>
                <Box>
                  <SaveButton onClick={async () => {
                    if (saveState === 'info' || !isValid(projectType)) { return; }
                    setSaveState('info');
                    const result = await (projectType?.id
                      ? api.updateProjectType(projectType)
                      : api.createProjectType(projectType)
                    );
                    if (result && !result.error) {
                      if (!projectType?.id) { setProjectType(emptyProjectType); }
                      setSaveState('success');
                    } else {
                      if (result?.error?.field && result.error.message) {
                        setFormErrors(e => ({ ...e, [result.error.field]: result.error.message }));
                      }
                      setSaveState('error');
                    }
                  }} />
                </Box>
              </Stack>

              <Grid container spacing={4} justifyContent="center">

                <Grid item xs={12} md={6}>
                  <Paper elevation={3} sx={{ p: 2 }}>
                    <Stack spacing={2}>
                      <Typography variant="button">Project Type Details</Typography>
                      <TextField label="Name" value={projectType.name} required
                        error={!!formErrors['name']} helperText={formErrors['name']}
                        onChange={updateProjectType('name')} fullWidth />
                      <TextField label="Slug" value={projectType.slug}
                        error={!!formErrors['slug']} helperText={formErrors['slug']}
                        onChange={updateProjectType('slug')} fullWidth />
                    </Stack>
                  </Paper>
                </Grid>
              </Grid>

              <SaveSnackbar state={saveState} setState={setSaveState} />
            </>
          )}

          { !projectType && (
            <>
              <div style={{ textAlign: 'center' }}>The project type was not found</div>
              <Stack direction="row" justifyContent='center' sx={{ pt: 4 }}>
                <BackButton />
              </Stack>
            </>
          )}

        </>
      )}
    </>
  );

};

export default ProjectType;
