import { Loader } from '@googlemaps/js-api-loader';
import constants from './constants';

// define types and singletons

const loader = new Loader({ apiKey: constants.googleMapsApiKey });
let googleGeocoder: google.maps.Geocoder|null = null;

export interface Address {
  street_number: string,
  street_name: string,
  city: string,
  state: string,
  zip: string,
  country: string,
}

interface MapLocation {
  latitude: number,
  longitude: number,
}

interface GeocodingResult {
  location: MapLocation,
  error: string,
}

// define methods

const isLoaded = () => {
  return !!googleGeocoder;
};

const load = async () => {
  try {
    if (isLoaded()) { return; }
    await loader.load();
    googleGeocoder = new window.google.maps.Geocoder();
  } catch (e) {
    console.log(e);
  }
};

const addressToString = (address: Address): string => {
  const tAddress: Address = {
    street_number: address.street_number.trim(),
    street_name: address.street_name.trim(),
    city: address.city.trim(),
    state: address.state.trim(),
    zip: address.zip.trim(),
    country: address.country.trim(),
  };
  if (!tAddress.street_number || !tAddress.street_name || !tAddress.state || !tAddress.country) {
    return '';
  }
  return tAddress.street_number + ' ' + tAddress.street_name +
    (tAddress.city ? ', ' + tAddress.city : '') + ', ' + tAddress.state +
    (tAddress.zip ? ', ' + tAddress.zip : '') + ', ' + tAddress.country;
};

const geocode = async (address: Address): Promise<GeocodingResult> => {
  const result: GeocodingResult = { location: { latitude: 0, longitude: 0 }, error: '' };
  return new Promise(async (resolve, reject) => {
    try {
      if (!googleGeocoder) { await load(); }
      if (!googleGeocoder) {
        result.error = 'Geocoder not loaded';
        resolve(result);
        return;
      }
      const addressStr = addressToString(address);
      if (!addressStr) {
        result.error = 'Incomplete address';
        resolve(result);
        return;
      }
      googleGeocoder.geocode({ address: addressStr }, (gResults, gStatus) => {
        switch (gStatus) {
          case 'OK':
            if (gResults?.length) {
              const location = gResults[0].geometry.location;
              result.location.latitude = location.lat();
              result.location.longitude = location.lng();
            } else {
              result.error = 'No results found';
            }
            break;
          case 'ZERO_RESULTS':
            result.error = 'No results';
            break;
          case 'OVER_QUERY_LIMIT':
            result.error = 'Over query limit';
            break;
          case 'REQUEST_DENIED':
            result.error = 'Request denied';
            break;
          case 'INVALID_REQUEST':
            result.error = 'Invalid request';
            break;
          case 'UNKNOWN_ERROR':
          case 'ERROR':
            result.error = 'An error occurred';
            break;
          default:
            result.error = 'Unknown response status: ' + gStatus;
            break;
        }
        resolve(result);
      });
    } catch (e) {
      console.log(e);
      result.error = 'A runtime error occurred';
      resolve(result);
    }
  });
};

// export

const geocoder = {
  isLoaded,
  load,
  addressToString,
  geocode,
};

export default geocoder;
