import CheckIcon from '@mui/icons-material/Check';
import Button from '@mui/material/Button';

import constants from '../../app/constants';

interface SaveButtonParams {
  onClick: () => void,
  label?: string,
}

const SaveButton = (params: SaveButtonParams) => {
  return (
    <Button variant="contained" color="success" sx={{ minWidth: constants.buttonMinWidth }}
      startIcon={<CheckIcon />} onClick={params.onClick}
    >
      {params.label || 'Save'}
    </Button>
  );

};

export default SaveButton;
