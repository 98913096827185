import { ChangeEvent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { DataGrid, GridColDef, GridFilterModel, GridSortModel, GridToolbar } from '@mui/x-data-grid';

import ReloadButton from '../ReloadButton/ReloadButton';
import api from '../../app/api';
import constants from '../../app/constants';
import * as Types from '../../app/types';

const Vendors = () => {

  // define state

  const [vendors, setVendors] = useState<Types.VendorListing[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [rowCount, setRowCount] = useState<number>(0);
  const [gridState, setGridState] = useState<Types.GridState>({
    page: 0, pageSize: constants.pageSizeOptions[0],
    filter: '', sort: '', pending_review: '', info_visible: ''
  });
  const [selectedStatus, setSelectedStatus] = useState<string>('');
  const initDelete = { isOpen: false, vendorId: 0, vendorName: '', failed: false };
  const [deleteState, setDeleteState] = useState(initDelete);
  const theme = useTheme();
  const isScreenMd = useMediaQuery(theme.breakpoints.up('md'));
  const isScreenSm = useMediaQuery(theme.breakpoints.up('sm'));

  // load vendors

  const loadVendors = async (gridState: Types.GridState) => {
    setIsLoading(true);
    const result = await api.getVendors(gridState);
    setVendors(result.vendors || []);
    setRowCount(result.count || 0);
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => await loadVendors(gridState))();
  }, [gridState]);

  // define grid columns

  const iconsStyle = { color: constants.listIconColor, mx: (isScreenSm ? 1 : 0) };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 2,
    },
    {
      field: 'slug',
      headerName: 'Slug',
      flex: 2,
      filterable: false,
      sortable: false,
      hide: !isScreenMd,
    },
    {
      field: 'info_visible',
      headerName: 'Info Visible',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      sortable: false,
      hide: !isScreenSm,
      renderCell: (params) => (
        <Switch
          checked={!!params.row.info_visible}
          onChange={async (e: ChangeEvent<HTMLInputElement>) => {
            const newVisible = e.target.checked;
            const result = await api.updateVendorInfoVisible(params.row.id, newVisible);
            if (!result) { return; }
            setVendors(vs => {
              const newVendors: Types.VendorListing[] = [...vs];
              const index = newVendors.findIndex(v => v.id === params.row.id);
              newVendors[index].info_visible = newVisible ? 1 : 0;
              return newVendors;
            });
          }}
        />
      ),
    },
    {
      field: 'pending_review',
      headerName: 'Review status',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      sortable: false,
      hide: !isScreenSm,
      renderCell: (params) => {
        if (!params.row.pending_review) { return 'Approved'; }
        const label = 'Pending | ' + (params.row.edited_vendor_id ? 'Edited' : 'New');
        const color = (params.row.edited_vendor_id ? 'info' : 'success');
        return (
          <Chip label={label} color={color} variant="outlined" />
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      disableExport: true,
      filterable: false,
      sortable: false,
      renderCell: (params) => (
        <>
          <IconButton component={Link} to={'/vendors/edit/' + params.row.id} sx={iconsStyle}>
            <EditIcon />
          </IconButton>
          <IconButton sx={iconsStyle} onClick={() => {
            setDeleteState({ isOpen: true, vendorId: params.row.id, vendorName: params.row.name, failed: false });
          }}>
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  // render

  const buttonsStyle = { mt: '2px', mx: (isScreenMd ? 4 : 2), mb: 2 };

  return (
    <>

      <Box sx={{ textAlign: 'center' }}>
        <Button variant="contained" startIcon={<AddIcon />} component={Link} to="/vendors/new" sx={buttonsStyle}>
          Add Vendor
        </Button>

        {isScreenSm && (
          <ReloadButton onClick={async () => await loadVendors(gridState)} sx={buttonsStyle} />
        )}

        <FormControl size="small" sx={{ ...buttonsStyle, width: 150, mt: 0 }}>
          <InputLabel id="status-label">Status</InputLabel>
          <Select
            labelId="status-label"
            id="status-select"
            value={selectedStatus}
            label="Status"
            onChange={e => {
              const newStatus = e.target.value;
              setSelectedStatus(newStatus);
              if (newStatus === 'info_visible') {
                setGridState(s => ({ ...s, info_visible: '1', pending_review: '' }));
              } else {
                setGridState(s => ({ ...s, info_visible: '', pending_review: newStatus }));
              }
            }}
            sx={{ backgroundColor: 'white' }}
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value="0">Approved</MenuItem>
            <MenuItem value="1">Pending</MenuItem>
            <MenuItem value="info_visible">Info visible</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <DataGrid
        rows={vendors}
        columns={columns}
        autoHeight
        rowsPerPageOptions={constants.pageSizeOptions}
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        disableSelectionOnClick
        disableColumnFilter
        disableColumnSelector
        sx={{ boxShadow: 2, backgroundColor: 'white' }}
        paginationMode="server"
        rowCount={rowCount}
        loading={isLoading}
        page={gridState.page}
        onPageChange={page => setGridState(s => ({ ...s, page }))}
        pageSize={gridState.pageSize}
        onPageSizeChange={pageSize => setGridState(s => ({ ...s, pageSize }))}
        filterMode="server"
        onFilterModelChange={(filterModel: GridFilterModel) => setGridState(s => ({
          ...s, filter: (filterModel?.quickFilterValues?.length ? filterModel.quickFilterValues.join(' ') : '')
        }))}
        sortingMode="server"
        onSortModelChange={(sortModel: GridSortModel) => setGridState(s => ({
          ...s, sort: (sortModel?.length ? sortModel[0].sort : '') + ''
        }))}
      />

      <Dialog open={deleteState.isOpen}
        onClose={() => setDeleteState(initDelete)}
        aria-describedby="delete-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="delete-dialog-description" sx={{ color: 'black' }}>
            {!deleteState.failed
              ? `Delete the vendor ${deleteState.vendorName}?`
              : `Please delete or reassign the vendor users first`
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteState(initDelete)} autoFocus>
            {!deleteState.failed ? 'No' : 'Close'}
          </Button>
          {!deleteState.failed && (
            <Button variant="contained" color="error" onClick={async () => {
              const vendorId = deleteState.vendorId;
              setDeleteState(initDelete);
              setIsLoading(true);
              const result = await api.deleteVendor(vendorId);
              if (result && !result.error) { await loadVendors(gridState); }
              else {
                setIsLoading(false);
                setDeleteState({ ...initDelete, isOpen: true, failed: true });
              }
            }}>
              Yes
            </Button>
          )}
        </DialogActions>
      </Dialog>

    </>
  );

};

export default Vendors;
