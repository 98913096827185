import { Auth } from 'aws-amplify';
import { ChangeEvent, FormEvent, useState } from 'react';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import constants from '../../app/constants';
import * as Types from '../../app/types';
import utils from '../../app/utils';

interface FormValues {
  email: string,
  password: string,
}

const Login = () => {

  // define state

  const [values, setValues] = useState<FormValues>({ email: '', password: '' });
  const [errors, setErrors] = useState<Types.FormErrors>({});
  const [formError, setFormError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // update, validate, and submit

  const updateValue = (field: string) => (e: ChangeEvent<HTMLInputElement>) => {
    setValues(v => ({ ...v, [field]: e.target.value}));
    setErrors(err => ({ ...err, [field]: '' }));
    setFormError('');
  };

  const isValid = (): boolean => {
    const _errors: Types.FormErrors = {};
    let email = values['email'].trim();
    if (!email) { _errors['email'] = 'Required'; }
    else if (!constants.emailRegEx.test(email)) { _errors['email'] = 'Invalid'; }
    if (!values['password']) { _errors['password'] = 'Required'; }
    setErrors(_errors);
    setFormError('');
    return !Object.keys(_errors).length;
  };

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (isLoading || !isValid()) { return; }
    setIsLoading(true);
    try {
      const user = await Auth.signIn(values['email'], values['password']);
      const idToken = user?.signInUserSession?.idToken?.jwtToken;
      if (!idToken) {
        // eslint-disable-next-line
        throw 'ID token not found.';
      }
      utils.setIdToken(idToken);
      window.location.replace(constants.adminUrl);
    } catch (error) {
      showError(error);
    }
    setIsLoading(false);
  };

  const showError = (error: any) => {
    const errorStr = error?.code || '';
    const _errors: Types.FormErrors = {};
    let _formError = '';
    if (errorStr === 'NotAuthorizedException') {
      _formError = 'Incorrect email or password';
      _errors['email'] = ' ';
      _errors['password'] = ' ';
    } else if (errorStr === 'UserNotConfirmedException') {
      _formError = 'The user\'s email has not been verified';
      _errors['email'] = ' ';
      _errors['password'] = ' ';
    } else {
      _formError = 'Error signing in';
    }
    setErrors(_errors);
    setFormError(_formError);
  };

  // render

  return (
    <>
      <div style={{ textAlign: 'center', padding: '48px 0' }}>
        <img src="/@4xBuildStack_Logo.png" width={166} height={44} alt="Buildstack" />
      </div>

      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <form onSubmit={onSubmit}>
              <Stack spacing={2} textAlign="center">

                <Typography variant="button">Log In</Typography>

                <TextField type="email" label="Email" value={values['email']}
                  error={!!errors['email']} helperText={(errors['email'] || '').trim()}
                  onChange={updateValue('email')} fullWidth autoFocus />

                <TextField type="password" label="Password" value={values['password']}
                  error={!!errors['password']} helperText={(errors['password'] || '').trim()}
                  onChange={updateValue('password')} fullWidth />

                <Button type="submit" variant="contained" size='large' disabled={isLoading}>
                  Submit
                </Button>

                {!!formError && (
                  <Typography variant="body1" color="error">{formError}</Typography>
                )}

              </Stack>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </>
  );

};

export default Login;
