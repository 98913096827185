
import axios from 'axios';
import constants from './constants';
import * as Types from './types';
import utils from './utils';

type Method = 'get' | 'post' | 'put' | 'delete';

// initialize Axios instance

const axiosInstance = axios.create({
  timeout: 30000,
  headers: { 'Authorization': utils.getIdToken() }
});

const onResponseSuccess = (response: any) => response;
const onResponseError = (error: any) => {
  const status = error.status || (error.response ? error.response.status : 0);
  if (constants.env !== 'development' && (status === 0 || status === 403)) { utils.logOut(); }
  return Promise.reject(error);
};
axiosInstance.interceptors.response.use(onResponseSuccess, onResponseError);

// helper functions

const _apiRequest = (path: string, method: Method = 'get', reqData: object = {}): any => {
  return _request(constants.apiUrl + path, method, reqData);
};

const _srvRequest = (path: string, method: Method = 'get', reqData: object = {}) => {
  return _request(constants.srvUrl + path, method, reqData);
};

const _request = async (path: string, method: Method = 'get', reqData: object = {}) => {
  let result = null;
  const isPostOrPut = (method === 'post' || method === 'put');
  try {
    const response = await (
      isPostOrPut && reqData
      ? axiosInstance[method](path, reqData)
      : axiosInstance[method](path)
    );
    if (response && typeof response.data === 'object') { result = response.data; }
  } catch (e) {
    result = _getErrorResult(e);
    if (!result) { utils.logError(e); }
  }
  return result;
};

const _getErrorResult = (exception: any) => {
  const msg = (exception?.response?.data?.error || '') + '';
  const result = {
    error: {
      field: '',
      message: '',
      code: '',
    }
  };
  if (msg.includes('UsernameExistsException')
    || msg.includes('AliasExistsException')) {
    result.error.field = 'email';
    result.error.message = 'The email already exists';
    return result;
  }
  if (msg.includes('Duplicate')) {
    if (msg.includes('.email')) {
      result.error.field = 'email';
      result.error.message = 'The email already exists';
      return result;
    }
    if (msg.includes('.name')) {
      result.error.field = 'name';
      result.error.message = 'The name already exists';
      return result;
    }
    if (msg.includes('.slug')) {
      result.error.field = 'slug';
      result.error.message = 'The slug already exists';
      return result;
    }
  }
  if (msg.includes('UserNotFoundException')) {
    result.error.code = 'UserNotFoundException';
    return result;
  }
  if (msg.includes('a foreign key constraint fails')) {
    result.error.code = 'ForeignKeyFails';
    return result;
  }
  return null;
};

const _gridStateToQuery = (gridState: Types.GridState|null, prefix: string = '?') => {
  if (!gridState) { return ''; }
  let query: string = prefix + 'offset=' + (gridState.page * gridState.pageSize)
    + '&limit=' + gridState.pageSize;
  if (gridState.filter) {
    query += '&filter=' + window.encodeURIComponent(gridState.filter);
  }
  if (gridState.sort) {
    query += '&sort=' + gridState.sort;
  }
  if (gridState.sort_by) {
    query += '&sort_by=' + gridState.sort_by;
  }
  if (gridState.pending_review) {
    query += '&pending_review=' + gridState.pending_review;
  }
  if (gridState.info_visible) {
    query += '&info_visible=' + gridState.info_visible;
  }
  if (gridState.group) {
    query += '&group=' + gridState.group;
  }
  return query;
};

// api endpoints

const getCountries = () => _apiRequest('public/countries');
const getStates = (countryId: number) => _apiRequest('public/countries/' + countryId + '/states');

const getCategories = () => _apiRequest('public/categories');
const getCategory = (slug: string) => _apiRequest('admin/categories/' + slug);
const createCategory = (c: Types.Category) => _apiRequest('admin/categories', 'post', c);
const updateCategory = (c: Types.Category) => _apiRequest('admin/categories/' + c.id, 'put', c);
const deleteCategory = (id: number|string) => _apiRequest('admin/categories/' + id, 'delete');

const getCategoryGroups = () => _apiRequest('public/category_groups');
const getCategoryGroup = (slug: string) => _apiRequest('admin/category_groups/' + slug);
const createCategoryGroup = (g: Types.CategoryGroup) => _apiRequest('admin/category_groups', 'post', g);
const updateCategoryGroup = (g: Types.CategoryGroup) => _apiRequest('admin/category_groups/' + g.id, 'put', g);
const deleteCategoryGroup = (id: number|string) => _apiRequest('admin/category_groups/' + id, 'delete');

const getJobs = (gridState: Types.GridState) => _apiRequest('public/jobs' + _gridStateToQuery(gridState));
const getJob = (id: number|string) => _apiRequest('public/jobs/' + id);
const createJob = (j: Types.JobDetail) => _apiRequest('admin/jobs', 'post', j);
const updateJob = (j: Types.JobDetail) => _apiRequest('admin/jobs/' + j.id, 'put', j);
const deleteJob = (id: number) => _apiRequest('admin/jobs/' + id, 'delete');

const getJobApps = (gridState: Types.GridState) => _apiRequest('admin/job_apps' + _gridStateToQuery(gridState));
const deleteJobApp = (jobId: number, userId: string) => _apiRequest('admin/job_apps/' + jobId + '/' + userId, 'delete');

const getProjects = (gridState: Types.GridState) => _apiRequest('admin/projects' + _gridStateToQuery(gridState));
const getProject = (id: number|string) => _apiRequest('admin/projects/' + id);
const createProject = (p: Types.ProjectDetail) => _apiRequest('admin/projects', 'post', _mapProject(p));
const updateProject = (p: Types.ProjectDetail) => _apiRequest('admin/projects/' + p.id, 'put', _mapProject(p));
const deleteProject = (id: number|string) => _apiRequest('admin/projects/' + id, 'delete');
const _mapProject = (p: Types.ProjectDetail) => ({ ...p,
  vendors: p.vendors
    .map(v => ({ vendor_id: v.vendor_id, category_id: v.category_id }))
    .filter(v => (v.vendor_id > 0 && v.category_id > 0)),
});

const getProjectImages = (id: number|string) => _apiRequest('admin/projects/' + id + '/images');
const updateProjectImages = (id: number|string, images: Types.ProjectImage[]) => _apiRequest(
  'admin/projects/' + id + '/images', 'put', { images }
);

const getProjectUpdates = (id: number|string) => _apiRequest('admin/projects/' + id + '/updates');
const updateProjectUpdates = (id: number|string, updates: Types.ProjectUpdate[]) => _apiRequest(
  'admin/projects/' + id + '/updates', 'put', { updates }
);

const getProjectTypes = () => _apiRequest('public/project_types');
const getProjectType = (slug: string) => _apiRequest('admin/project_types/' + slug);
const createProjectType = (t: Types.ProjectType) => _apiRequest('admin/project_types', 'post', t);
const updateProjectType = (t: Types.ProjectType) => _apiRequest('admin/project_types/' + t.id, 'put', t);
const deleteProjectType = (id: number|string) => _apiRequest('admin/project_types/' + id, 'delete');

const getVendors = (gridState: Types.GridState) => _apiRequest('admin/vendors' + _gridStateToQuery(gridState));
const getVendor = (id: number|string) => _apiRequest('admin/vendors/' + id);
const getVendorCategories = (id: number|string) => _apiRequest('public/vendors/' + id + '/categories');
const createVendor = (v: Types.VendorDetail) => _apiRequest('admin/vendors', 'post', _mapVendor(v));
const updateVendor = (v: Types.VendorDetail) => _apiRequest('admin/vendors/' + v.id, 'put', _mapVendor(v));
const updateVendorInfoVisible = (id: number|string, info_visible: boolean) =>
  _apiRequest('admin/vendors/' + id + '/info_visible', 'put', { info_visible });
const deleteVendor = (id: number|string) => _apiRequest('admin/vendors/' + id, 'delete');
const _mapVendor = (v: Types.VendorDetail) => ({ ...v, categories: v.categories
  .map(c => c.id).filter(id => id > 0)
});

const getItemsToGeocode = (itemType: string, lastId: number, limit: number) => _apiRequest(
  'admin/items_to_geocode?itemType=' + itemType + '&lastId=' + lastId + '&limit=' + limit
);
const updateItemLocation = (itemType: string, item: Types.ProjectDetail|Types.VendorDetail) => _apiRequest(
  'admin/item_location?itemType=' + itemType + '&id=' + item.id +
  '&latitude=' + item.latitude + '&longitude=' + item.longitude,
  'put'
);

const pendingInvites = () => _apiRequest('user/slack/pending', 'get');

// srv endpoints

const listProjectImages = (id: number|string) =>
  _srvRequest('admin/projects/' + id + '/images');
const uploadProjectImage = async (id: number|string, name: string, data: string) =>
  _srvRequest('admin/projects/' + id + '/images/' + name, 'post', {data});
const deleteProjectImage = (id: number|string, name: string) =>
  _srvRequest('admin/projects/' + id + '/images/' + name, 'delete');
const deleteAllProjectImages = (id: number|string) =>
  _srvRequest('admin/projects/' + id + '/all_images', 'delete');

const deleteJobFiles = (id: number) =>
  _srvRequest('admin/jobs/' + id, 'delete');
const deleteJobAppFiles = (jobId: number, userId: string) =>
  _srvRequest('admin/job_apps/' + jobId + '/' + userId, 'delete');

// user endpoints (api and srv)

const getMyUser = () => _apiRequest('user/me');

const getUser = async (id: string) => {
  const apiUser = await _apiRequest('admin/users/' + id);
  if (!apiUser.id || !apiUser?.email) { return apiUser; }
  const srvUser = await _srvRequest('admin/users/' + id);
  if (!srvUser.id || !srvUser?.email) { return srvUser; }
  return { ...apiUser, ...srvUser };
};

const getUsers = (gridState: Types.GridState) => _apiRequest('admin/users' + _gridStateToQuery(gridState));

const createUser = async (u: Types.UserDetail) => {
  const srvResult = await _srvRequest('admin/users', 'post', u);
  if (!srvResult || srvResult.error || !srvResult.id) { return srvResult; }
  const apiResult = await _apiRequest('admin/users', 'post', { ...u, id: srvResult.id });
  return apiResult;
};

const updateUser = async (u: Types.UserDetail) => {
  const apiResult = await _apiRequest('admin/users/' + u.id, 'put', u);
  if (!apiResult || apiResult.error) { return apiResult; }
  const srvResult = await _srvRequest('admin/users/' + u.id, 'put', u);
  return srvResult;
};

const deleteUser = async (id: string) => {
  let srvResult, apiResult;
  try {
    srvResult = await _srvRequest('admin/users/' + id, 'delete');
    if (!srvResult || (srvResult.error && srvResult.error.code !== 'UserNotFoundException')) {
      return srvResult;
    }
    apiResult = await _apiRequest('admin/users/' + id, 'delete');
  } catch (e) { console.log(e); }
  return apiResult;
};

const updateSlackInviteStatus = (status: string, id: number|string) => _apiRequest('user/slack/update/' +  status + '/' + id, 'put');


// export

const api = {
  getCountries,
  getStates,

  getCategories,
  getCategory,
  createCategory,
  updateCategory,
  deleteCategory,

  getCategoryGroups,
  getCategoryGroup,
  createCategoryGroup,
  updateCategoryGroup,
  deleteCategoryGroup,

  getJobs,
  getJob,
  createJob,
  updateJob,
  deleteJob,

  getJobApps,
  deleteJobApp,

  getProjects,
  getProject,
  createProject,
  updateProject,
  deleteProject,

  getProjectImages,
  updateProjectImages,
  getProjectUpdates,
  updateProjectUpdates,

  getProjectTypes,
  getProjectType,
  createProjectType,
  updateProjectType,
  deleteProjectType,

  getVendors,
  getVendor,
  getVendorCategories,
  createVendor,
  updateVendor,
  updateVendorInfoVisible,
  deleteVendor,

  getItemsToGeocode,
  updateItemLocation,

  getMyUser,
  getUser,
  getUsers,

  createUser,
  updateUser,
  deleteUser,

  listProjectImages,
  uploadProjectImage,
  deleteProjectImage,
  deleteAllProjectImages,

  deleteJobFiles,
  deleteJobAppFiles,
  pendingInvites,
  updateSlackInviteStatus,
};

export default api;
