import CachedIcon from '@mui/icons-material/Cached';
import Button from '@mui/material/Button';

interface ReloadParams {
  onClick: () => void,
  sx?: any,
}

const ReloadButton = (params: ReloadParams) => {
  return (
    <Button variant="contained" color="info" sx={params.sx || {}}
      startIcon={<CachedIcon />} onClick={params.onClick}
    >
      Reload
    </Button>
  );

};

export default ReloadButton;
