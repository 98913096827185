import { MouseEvent, useEffect, useLayoutEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Logout from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import api from '../../app/api';
import constants from '../../app/constants';
import * as Types from '../../app/types';
import utils from '../../app/utils';

const Nav = () => {

  // screen size breakpoints

  const theme = useTheme();
  const isScreenLg = useMediaQuery(theme.breakpoints.up('lg'));

  // update anchor of the dropdown menu

  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [userMenuAnchor, setUserMenuAnchor] = useState<null | HTMLElement>(null);

  const openMenu = (event: MouseEvent<HTMLElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  const openUserMenu = (event: MouseEvent<HTMLElement>) => {
    setUserMenuAnchor(event.currentTarget);
  };

  const closeMenu = () => {
    setMenuAnchor(null);
    setUserMenuAnchor(null);
  };

  useLayoutEffect(() => {
    window.addEventListener('resize', closeMenu);
    return () => window.removeEventListener('resize', closeMenu);
  }, []);

  // update tab after location changes

  const [selectedTab, setSelectedTab] = useState<number>(0);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    for (let i = 0; i < constants.routes.length; i++) {
      const route = constants.routes[i];
      if (route.path === location.pathname) {
        setSelectedTab(i);
        return;
      }
    }
  }, [location]);

  // get user info

  const [user, setUser] = useState<Types.UserInfo|null>(null);

  useEffect(() => {
    (async () => {
      setUser(await api.getMyUser());
    })();
  }, []);

  const isAuthorized = (user: Types.UserInfo|null, route: Types.Route): boolean => {
    return !!(!route.groups.length || (
      user?.group && route.groups.includes(user.group)
    ));
  };

  // render

  return (
    <AppBar position="static" sx={{ backgroundColor: '#fff', color: '#000' }}>
      <Container>
        <Toolbar disableGutters>

          {/* Responsive menu */}
          { !isScreenLg && (
            <Box sx={{ flexGrow: 1, display: 'flex' }}>
              <IconButton
                size="large"
                aria-label="Menu"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={openMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={menuAnchor}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(menuAnchor)}
                onClose={closeMenu}
              >
                {constants.routes.map((route, index) => {
                  if (!isAuthorized(user, route)) { return false; }
                  return (
                    <MenuItem key={index} onClick={() => { navigate(route.path);  closeMenu(); }}>
                      <Typography>{route.label}</Typography>
                    </MenuItem>
                  );
                })}
              </Menu>
            </Box>
          )}

          {/* Tabs */}
          { isScreenLg && (
            <Tabs value={selectedTab} sx={{ flexGrow: 1, display: 'flex' }}>
              {constants.routes.map((route, index) => {
                if (!isAuthorized(user, route)) { return false; }
                return (
                  <Tab key={index} value={index} label={route.label} to={route.path} component={Link} />
                );
              })}
            </Tabs>
          )}

          {/* User menu */}
          {!!user && (
            <>
              <Button onClick={openUserMenu}
                sx={{ m: 2, color: 'inherit' }}
                aria-controls="user-menu-appbar"
                aria-haspopup="true"
              >
                Account
              </Button>
              <Menu
                id="user-menu-appbar"
                anchorEl={userMenuAnchor}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(userMenuAnchor)}
                onClose={closeMenu}
                sx={{ width: 230 }}
              >
                <MenuItem sx={{ cursor: 'auto' }}>
                  <ListItemIcon>
                    <AccountCircleIcon />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography variant="body2" noWrap title={user.email}>
                      {user.email}
                    </Typography>
                    <Typography variant="caption" noWrap>
                      {user.group}
                    </Typography>
                  </ListItemText>
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => { closeMenu();  utils.logOut(); }}>
                  <ListItemIcon>
                    <Logout />
                  </ListItemIcon>
                  <ListItemText>
                    Log out
                  </ListItemText>
                </MenuItem>
              </Menu>
            </>
          )}

        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Nav;
