
const adminUrl = window.location.protocol + '//' + window.location.host + '/';

const constants = {
  env: process.env.REACT_APP_NODE_ENV,

  googleMapsApiKey: 'AIzaSyCV3kI3hJMD_ZQkBmzVWV_QyR-JUI1beaE',

  // URLs
  adminUrl: adminUrl,
  apiUrl: process.env.REACT_APP_API_URL,  // database API
  srvUrl: process.env.REACT_APP_SERVER_URL,  // server API
  bucketUrl: 'https://buildstack-public.s3.ca-central-1.amazonaws.com/',

  // routes
  userGroups: [ 'User', 'PremiumUser', 'Admin', 'SuperAdmin' ],
  routes: [
    { path: '/projects', label: 'Projects', groups: [] },
    { path: '/vendors', label: 'Vendors', groups: [] },
    { path: '/categories', label: 'Categories', groups: [] },
    { path: '/category-groups', label: 'Category groups', groups: [] },
    { path: '/project-types', label: 'Project types', groups: [] },
    { path: '/jobs', label: 'Jobs', groups: [] },
    { path: '/job-apps', label: 'Applications', groups: [] },
    { path: '/users', label: 'Users', groups: ['SuperAdmin'] },
    { path: '/slack-invites', label: 'Invites', groups: [] },
  ],

  // UI
  defaultCountryId: 32,
  listIconColor: 'rgba(0, 0, 0, 0.87)',
  emailRegEx: /^[^@\s]+@[^@\s]+\.[^@\s.]{2,}$/,
  urlRegEx: /[^.]+\.[a-zA-Z]{2,}$/,
  pageSizeOptions: [10, 25, 50],
  buttonMinWidth: 110,
};

export default constants;
