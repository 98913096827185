import { SyntheticEvent } from 'react';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import * as Types from '../../app/types';

interface UploadParams {
  state: Types.SaveState,
  setState: (s: Types.SaveState) => any,
  numFiles: number,
  currentFile: number,
  numErrors: number,
}

const UploadSnackbar = (params: UploadParams) => {
  
  const uploadClosed = (event?: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') { return; }
    params.setState('closed');
  };

  return (
    <Snackbar open={params.state !== 'closed'}
      autoHideDuration={params.state === 'info' ? null : 6000}
      onClose={uploadClosed}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      {params.state !== 'closed' ? (
        <Alert sx={{ width: '100%' }} elevation={6} variant="filled" onClose={uploadClosed} severity={params.state}>
          {params.state === 'info' && (
            'Uploading: ' + params.currentFile + ' of ' + params.numFiles
          )}
          {params.state === 'success' && (
            'Completed'
          )}
          {params.state === 'error' && (params.numErrors
            ? `${params.numErrors} file${params.numErrors === 1 ? '' : 's'} skipped`
            : 'An error occurred'
          )}
        </Alert>
      ) : (
        <div></div>
      )}
    </Snackbar>
  );

};

export default UploadSnackbar;
