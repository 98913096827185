import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import { DataGrid, GridColDef, GridFilterModel, GridSortModel, GridToolbar } from '@mui/x-data-grid';

import constants from '../../app/constants';
import * as Types from '../../app/types';
import api from '../../app/api';
import { DialogContent } from '@mui/material';

const SlackInvites = () => {
    const theme = useTheme();

    const [users, setUsers] = useState<Types.UserListing[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [rowCount, setRowCount] = useState<number>(0);
    const [gridState, setGridState] = useState<Types.GridState>({
        page: 0, pageSize: constants.pageSizeOptions[0],
        filter: '', sort: '', group: '', pending_review: ''
    });
    const [selectedUser, setSelectedUser] = useState<Types.UserListing | null>(null);

    const isScreenSm = useMediaQuery(theme.breakpoints.up('sm'));

    const loadUsers = async () => {
        setIsLoading(true);
        const result:any = await api.pendingInvites();
        setUsers(result.users || []);
        setRowCount(result.count || 0);
        setIsLoading(false);
    };
    
    useEffect(() => {
    (async () => await loadUsers())();
    }, []);

    const columns: GridColDef[] = [
        {
          field: 'first_name',
          headerName: 'First name',
          flex: 1,
          filterable: false,
          sortable: false,
          hide: !isScreenSm,
        },
        {
          field: 'last_name',
          headerName: 'Last name',
          flex: 1,
          filterable: false,
          sortable: false,
          hide: !isScreenSm,
        },
        {
          field: 'email',
          headerName: 'Email',
          flex: 2,
        },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            disableExport: true,
            filterable: false,
            sortable: false,
            renderCell: (params) => (
              <>
                <Button
                    variant="contained"
                    onClick={ async () => {
                        setSelectedUser(params.row);
                    }}
                >
                    Invitation Sent
                </Button>
              </>
            ),
          },
        ];

    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
            <DataGrid
                rows={users}
                columns={columns}
                autoHeight
                rowsPerPageOptions={constants.pageSizeOptions}
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                },
                }}
                disableSelectionOnClick
                disableColumnFilter
                disableColumnSelector
                sx={{ boxShadow: 2, backgroundColor: 'white' }}
                paginationMode="server"
                rowCount={rowCount}
                loading={isLoading}
                page={gridState.page}
                onPageChange={page => setGridState(s => ({ ...s, page }))}
                pageSize={gridState.pageSize}
                onPageSizeChange={pageSize => setGridState(s => ({ ...s, pageSize }))}
                filterMode="server"
                onFilterModelChange={(filterModel: GridFilterModel) => setGridState(s => ({
                ...s, filter: (filterModel?.quickFilterValues?.length ? filterModel.quickFilterValues.join(' ') : '')
                }))}
                sortingMode="server"
                onSortModelChange={(sortModel: GridSortModel) => setGridState(s => ({
                ...s, sort: (sortModel?.length ? sortModel[0].sort : '') + ''
                }))}
            />
            </Box>

            <Dialog open={!!selectedUser} maxWidth="xs">
                <DialogTitle>
                <Stack direction="row" alignItems="center" spacing={isScreenSm ? 4 : 1}>
                    <Box sx={{ flexGrow: 1 }}>
                        <p>Click OK to confirm you've invited <b>{selectedUser?.first_name} {selectedUser?.last_name}</b> to Slack</p>
                    </Box>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1}>
                    <Button
                        variant="contained"
                        onClick={ async () => {
                            setSelectedUser(null);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={ async () => {
                            await api.updateSlackInviteStatus('invited', selectedUser?.id || 0);
                            await loadUsers();
                            setSelectedUser(null);
                        }}
                    >
                        OK
                    </Button>
                </Stack>
                </DialogTitle>
                <DialogContent></DialogContent>
            </Dialog>
        </>
    );

};

export default SlackInvites;