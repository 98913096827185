import Typography from '@mui/material/Typography';

interface SubTextParams {
  text?: string | number,
  subText: string | number,
  fullWidth?: boolean,
}

const SubText = (params: SubTextParams) => {
  return (
    <Typography variant="body2" sx={{
      pr: 2, width: (params.fullWidth ? '100%' : 'initial')
    }}>
      {params.text === params.subText ? '' : params.subText}
    </Typography>
  );
};

export default SubText;
